<template>
  <div>
    <el-card
      :body-style="{ padding: '0px' }"
      v-if="transactionDetails && transactionDetails.user"
    >
      <div class="transaction-info">
        <div class="t-number u-bg--normal-text-color">
          {{ transactionDetails.orderId }}
        </div>
        <div class="t-date u-font--small">
          {{ transactionDetails.createdAt | moment("DD MMM YYYY  h:mm a") }}
        </div>
      </div>
      <div class="user-info">
        <div
          class="user-avatar-name u-top-margin--2x u-color--normal-text-color"
        >
          <div
            :class="`u-avatar u-font--xsuper  ${
              $store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'
            }`"
          >
            <i class="el-icon-user"></i>
          </div>
          <div
            class="u-sname-id user-id"
            @click="() => handleNavigateToEntity(transactionDetails.user.id)"
          >
            <div class="u-font--bold">
              {{ transactionDetails.user.storeName }}
            </div>
            <div class="u-color--small-text-color u-font--small">
              # {{ transactionDetails.user.id }}
            </div>
          </div>
        </div>
        <div class="u-name-phone u-top-margin--2x u-color--normal-text-color">
          <div class="u-font--bold">{{ transactionDetails.user.name }}</div>
          <div>{{ transactionDetails.user.phone }}</div>
          <div>{{ transactionDetails.user.landline }}</div>
          <div>{{ transactionDetails.user.secondPhone }}</div>
        </div>
        <div class="spacer"></div>

        <div class="user-area">
          {{ transactionDetails.user.area.name }}
          <br />
          {{ transactionDetails.user.city }}
          <br />
          {{ transactionDetails.user.address }}
        </div>
        <div class="spacer"></div>
        <div
          class="user-avatar-name u-top-margin--2x u-color--normal-text-color"
        >
          <div
            :class="`u-avatar u-font--super  ${
              $store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'
            }`"
          >
            <i class="el-icon-coin"></i>
          </div>
          <div class="u-sname-id">
            <div class="u-font--bold">
              {{ $t("TransactionDetailsPageInfoPaymentMethodLabel") }}
            </div>
            <div class="u-color--small-text-color u-font--small">
              {{ transactionDetails.method }}
            </div>
          </div>
        </div>
        <div class="spacer"></div>
        <div
          class="user-avatar-name u-top-margin--2x u-color--normal-text-color"
        >
          <div
            :class="`u-avatar u-font--super  ${
              $store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'
            }`"
          >
            <i class="el-icon-s-flag"></i>
          </div>
          <div
            :class="`u-sname-id ${transactionDetails.user.addressLandmark ? 'user-landmark' : ''}`"
            @click="
              () =>
                handleNavigateToSalesAgent(
                  transactionDetails.user.addressLandmark
                )
            "
          >
            <div class="u-font--bold">
              {{ $t("TransactionDetailsPageInfoLandmarkLabel") }}
            </div>
            <div class="u-color--small-text-color u-font--small">
              {{
                transactionDetails.user.addressLandmark
                  ? transactionDetails.user.addressLandmark
                  : this.$t("TransactionDetailsPageLandmarkNoDataLabel")
              }}
            </div>
          </div>
        </div>
        <div class="spacer"></div>
        <div
          class="user-avatar-name u-top-margin--2x u-color--normal-text-color"
        >
          <div
            :class="`u-avatar u-font--super  ${
              $store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'
            }`"
          >
            <i class="el-icon-shopping-cart-2"></i>
          </div>
          <div class="u-sname-id">
            <div class="u-font--bold">
              {{ transactionDetails.section.selling_type }}
            </div>
            <div class="u-color--small-text-color u-font--small">
              {{ transactionDetails.section.title }}
            </div>
          </div>
        </div>

        <template v-if="transactionDetails.user.notes">
          <div class="spacer"></div>
          <div
            class="user-avatar-name u-top-margin--2x u-color--normal-text-color"
          >
            <div
              :class="`u-avatar u-font--super  ${
                $store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'
              }`"
            >
              <i class="el-icon-s-order"></i>
            </div>
            <div class="u-sname-id">
              <div class="u-font--small">
                {{ transactionDetails.user.notes }}
              </div>
            </div>
          </div>
        </template>
        <template v-if="transactionDetails.notes">
          <div class="spacer"></div>
          <div
            class="user-avatar-name u-top-margin--2x u-color--normal-text-color"
          >
            <div
              :class="`u-avatar u-font--super  ${
                $store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'
              }`"
            >
              <i class="el-icon-s-order"></i>
            </div>
            <div class="u-sname-id">
              <div class="u-font--small">
                {{ transactionDetails.notes }}
              </div>
            </div>
          </div>
          <!-- <div class="spacer"></div> -->
        </template>
        <div class="u-top-margin--2x u-bottom-margin--2x">
          <!-- <div class="u-avatar u-font--super u-right-margin--2x"> -->
          <!-- <i class="el-icon-s-order"></i> -->
          <!-- </div> -->

          <!--<div class="u-sname-id">
            <div class="u-font--bold u-color--normal-text-color">
               {{ $t("TransactionDetailsPageInfoInternalNoteLabel") }}
              User Note
            </div>
            <div>
              <text-area-action
                style="margin-top: 1rem"
                v-model="transactionDetails.user.internalNote"
                @check-click="saveInternalNote"
              ></text-area-action>
            </div>
          </div>
          -->
          <el-collapse accordion class="">
            <el-collapse-item name="1">
              <template slot="title">
                <div class="u-font--bold u-color--normal-text-color">
                  <!-- {{ $t("TransactionDetailsPageInfoInternalNoteLabel") }} -->
                  User Note
                </div>
              </template>
              <div>
                <text-area-action
                  style="margin-top: 1rem"
                  v-model="transactionDetails.user.internalNote"
                  @check-click="saveInternalNote"
                ></text-area-action>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <!-- <div class="spacer"></div> -->
      </div>
      <div class="total-cash u-color--normal-text-color">
        <div class="u-font--xsmall">
          {{ $t("TransactionDetailsPageInfoTotalCashLabel") }}
        </div>
        <div class="u-font--xsmall">
          <span class="u-font--large u-font--bold">
            {{ transactionTotalAmount }} </span
          >&nbsp; EGP
        </div>
      </div>
      <div class="user-info">
        <div class="u-font--bold u-color--normal-text-color u-top-margin--1x">
          TRX Note
        </div>
        <textarea
          type="textarea"
          rows="3"
          placeholder="Internal Note"
          v-model="internalNoteValue"
          @keydown="inputHandler"
          class="internal-note-text-area u-top-margin--1x"
        />
        <div class="u-font--bold">Hint: Press enter to save</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import TextAreaAction from "../../../../components/dataContainers/TextAreaAction.vue";
import { transactions } from "@/services";
import { router } from "@/router";

export default {
  name: "TransactionMeta",
  components: {
    TextAreaAction,
  },
  computed: {
    transactionDetails() {
      return this.$store.getters.getTransactionDetails;
    },
    transactionTotalAmount() {
      return this.$store.getters.getTransactionTotalAmount;
    },
  },
  data() {
    return {
      internalNoteValue: "",
    };
  },
  methods: {
    saveInternalNote() {
      try {
        transactions.updateInternalNote(
          this.transactionDetails.user.id,
          this.transactionDetails.user.internalNote
        );
      } catch (error) {
        this.$message.error(
          this.$t("TransactionDetailsSaveInternalNoteErrorMessage")
        );
      } finally {
        this.$loading().close();
      }
    },
    inputHandler(e) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        this.submitForm();
      }
    },
    async submitForm() {
      // if (this.internalNoteValue) {
      try {
        let trxId = this.transactionDetails.id;
        let requestBody = {
          internal_note: this.internalNoteValue.trim(),
        };
        const response = await transactions.updateTransaction(
          trxId,
          requestBody
        );
        if (response && response.status == 200) {
          this.$globalFunctions.popupMessageWrapper(
            "Internal note updated successfully",
            "success",
            2000
          );
        }
      } catch (error) {
        console.log("error :>> ", error);
        const errorMessage = this.$globalFunctions.errorMessageExtractor(error);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 2000);
      }
      // }
    },

    handleNavigateToEntity(id) {
      let url = router.resolve({ name: `entities/retailers/${id}` });
      window.open(`${url.href}${url.route.name}`, "_blank");
    },
    handleNavigateToSalesAgent(agentCode) {
      if (agentCode) {
        let url = router.resolve({ name: `entities/faturaTeam/salesAgents` });
        window.open(`${url.href}${url.route.name}?agentCode=${agentCode}`, "_blank");
      }
    },
  },
  watch: {
    transactionDetails(newValue) {
      if (newValue && newValue.internal_note) {
        this.internalNoteValue = `${newValue.internal_note}`;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../styles/wrench-kit/settings/_settings.global.scss";

/* global styles */
.el-message-box__btns {
  direction: rtl;

  button:nth-child(2) {
    margin-left: 0;
  }

  button:nth-child(1) {
    margin-left: 10px;
  }
}

$right-card-bg-color: #ffc1442e;
.checkbox-item::v-deep {
  .el-checkbox__label {
    font-size: 1rem !important;
    font-weight: bold;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $wrench-color-normal-text-color !important;
  }
  .el-checkbox__inner {
    border-radius: 50%;
    padding: 0.2rem;
  }
  border: none !important;
  color: $wrench-color-normal-text-color !important;
  border-radius: 24px !important;
  background-color: #40579312;
  margin-top: 0.5rem;
}
.spacer {
  height: 1px;
  background: #abb5d0;
  // border: 1px solid #abb5d0;
  opacity: 0.25;
  margin: 1rem 0rem;
}
.c-distributers-header {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.c-distributers-header__title {
  margin-right: auto;
  font-size: 24px;
  font-weight: bold;
}
.small-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.blacklist {
  background: rgb(84, 92, 100);
}
.original {
  background: #85ce61;
}
.receipt {
  background: #cb0300;
}

//Whole page container
.c-transaction-details-container::v-deep {
  .back-btn {
    text-align: left;
    span {
      cursor: pointer;
    }
  }

  .el-page-header__content {
    width: 100%;
  }

  .t-coulumn {
    border-radius: 4px;
    color: $wrench-color-normal-text-color !important;
    font-weight: bold;
    font-size: 0.75rem;
    background-color: #f5f6fa !important;

    &:hover {
      background-color: #f5f6fa !important;
    }
  }

  .el-card {
    border: none;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 4px 4px 0 rgba(43, 45, 55, 0.01);
  }
  .el-collapse-item__header {
    height: auto;
  }
  [class^="reciept-container"] {
    .el-collapse {
      border: none;
      .el-collapse-item__header {
        padding: 2rem;
      }
      //receipt-card-title
      .c-header-title {
        color: $wrench-color-normal-text-color;
      }
      .c-reciept-header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        .c-header-tags {
          display: flex;
          flex-wrap: wrap;
        }
        .right-container {
          flex-basis: 15%;
          flex-direction: row-reverse;
        }

        [class^="header-tag"] {
          line-height: 19px;

          border-radius: 6px;
          color: #ffffff;
          padding: 1px 9px;
          font-weight: 900;
          margin-right: 0.4rem;
          margin-top: 0.4rem;
          background-color: #545c64;
        }
        [class*="--red"] {
          background-color: #ff595e;
        }
        [class*="--green"] {
          background-color: #0dad2f;
        }
        [class*="--blue"] {
          background-color: #5977ff;
        }
        [class*="--valid"] {
          color: $wrench-color-normal-text-color;
          background-color: #8ac92663;
          padding: 5px;

          font-size: 1.13rem;
          box-shadow: 0 5px 4px 0 rgba(43, 45, 55, 0.03);
        }
        [class*="--unvalid"] {
          background-color: #ff595e;
          padding: 5px;

          font-size: 1.13rem;
          box-shadow: 0 5px 4px 0 rgba(43, 45, 55, 0.03);
        }
      }
      .card-body {
        padding: 30px;
        //before table
        .c-fatura-card__header-buttons {
          .steal-btn {
            flex-basis: 25%;
            .el-button {
              width: 100%;
              justify-content: space-between;
            }
          }
          margin-left: auto;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        table {
          border-spacing: 5px;
          border-collapse: separate;
        }
      }
    }

    //reciecpt container styling
    border: none;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0);
    border-left: 15px solid #fccd13;
    margin: 1rem;
    box-shadow: 0 0 32px 0 rgba(12, 10, 61, 0.04);
  }
  //user reciept item
  [class*="--item"] {
    border-left: 15px solid #545c64;
  }
  //***************************************************************************** */
  //*********** */ transaction infomations*********************************************
  //***************************************************************************** */
  .transaction-info {
    display: flex;
    padding: 11px 15px;
    align-items: center;
    background-color: $right-card-bg-color;

    .t-number {
      color: #ffffff;
      padding: 2px 6px;
      border-radius: 4px;
      margin-right: 1rem;
    }
  }

  .user-info {
    padding: 0px 25px;

    .user-avatar-name {
      display: flex;
      align-items: center;
      .u-avatar {
        border-radius: 14px;

        padding: 8px 15px;
        background-color: $right-card-bg-color;
      }
    }
    .u-name-phone {
      text-align: center;
    }

    .user-area {
      text-align: center;
      font-weight: 600;
      color: #545c64;
    }
  }
  .total-cash {
    background-color: $right-card-bg-color;
    padding: 1em;
    text-align: center;
  }

  .card-btn {
    width: 100%;
    font-weight: 900 !important;
    padding: 13px 0px;
    // background-color: $right-card-bg-color;
  }
  .save-btn {
    color: #545c64;
  }
  .progress-btn {
    background-color: #545c64;
    color: #ffffff;
    margin-top: 0.75rem;
  }
}
.user-id, .user-landmark {
  cursor: pointer;
}

// differant direction styles
[dir="rtl"] {
  .reciept-container {
    border: none;
    border-right: 15px solid #fccd13;
  }
  .reciept-container--item {
    border: none;
    border-right: 15px solid #545c64;
  }

  .t-number {
    margin-right: auto;
    margin-left: 1rem;
  }
  .back-btn {
    text-align: right;
  }
  .el-icon-arrow-left {
    transform: rotateY(180deg);
  }
}

.internal-note-text-area {
  // margin-top: 20px;
  // margin: 0px 25px;
  // margin: 20px;
  width: 98%;
  outline: none;
}
</style>
