var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.transactionDetails && _vm.transactionDetails.user)?_c('el-card',{attrs:{"body-style":{ padding: '0px' }}},[_c('div',{staticClass:"transaction-info"},[_c('div',{staticClass:"t-number u-bg--normal-text-color"},[_vm._v(" "+_vm._s(_vm.transactionDetails.orderId)+" ")]),_c('div',{staticClass:"t-date u-font--small"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.transactionDetails.createdAt,"DD MMM YYYY h:mm a"))+" ")])]),_c('div',{staticClass:"user-info"},[_c('div',{staticClass:"user-avatar-name u-top-margin--2x u-color--normal-text-color"},[_c('div',{class:`u-avatar u-font--xsuper  ${
            _vm.$store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'
          }`},[_c('i',{staticClass:"el-icon-user"})]),_c('div',{staticClass:"u-sname-id user-id",on:{"click":() => _vm.handleNavigateToEntity(_vm.transactionDetails.user.id)}},[_c('div',{staticClass:"u-font--bold"},[_vm._v(" "+_vm._s(_vm.transactionDetails.user.storeName)+" ")]),_c('div',{staticClass:"u-color--small-text-color u-font--small"},[_vm._v(" # "+_vm._s(_vm.transactionDetails.user.id)+" ")])])]),_c('div',{staticClass:"u-name-phone u-top-margin--2x u-color--normal-text-color"},[_c('div',{staticClass:"u-font--bold"},[_vm._v(_vm._s(_vm.transactionDetails.user.name))]),_c('div',[_vm._v(_vm._s(_vm.transactionDetails.user.phone))]),_c('div',[_vm._v(_vm._s(_vm.transactionDetails.user.landline))]),_c('div',[_vm._v(_vm._s(_vm.transactionDetails.user.secondPhone))])]),_c('div',{staticClass:"spacer"}),_c('div',{staticClass:"user-area"},[_vm._v(" "+_vm._s(_vm.transactionDetails.user.area.name)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.transactionDetails.user.city)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.transactionDetails.user.address)+" ")]),_c('div',{staticClass:"spacer"}),_c('div',{staticClass:"user-avatar-name u-top-margin--2x u-color--normal-text-color"},[_c('div',{class:`u-avatar u-font--super  ${
            _vm.$store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'
          }`},[_c('i',{staticClass:"el-icon-coin"})]),_c('div',{staticClass:"u-sname-id"},[_c('div',{staticClass:"u-font--bold"},[_vm._v(" "+_vm._s(_vm.$t("TransactionDetailsPageInfoPaymentMethodLabel"))+" ")]),_c('div',{staticClass:"u-color--small-text-color u-font--small"},[_vm._v(" "+_vm._s(_vm.transactionDetails.method)+" ")])])]),_c('div',{staticClass:"spacer"}),_c('div',{staticClass:"user-avatar-name u-top-margin--2x u-color--normal-text-color"},[_c('div',{class:`u-avatar u-font--super  ${
            _vm.$store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'
          }`},[_c('i',{staticClass:"el-icon-s-flag"})]),_c('div',{class:`u-sname-id ${_vm.transactionDetails.user.addressLandmark ? 'user-landmark' : ''}`,on:{"click":() =>
              _vm.handleNavigateToSalesAgent(
                _vm.transactionDetails.user.addressLandmark
              )}},[_c('div',{staticClass:"u-font--bold"},[_vm._v(" "+_vm._s(_vm.$t("TransactionDetailsPageInfoLandmarkLabel"))+" ")]),_c('div',{staticClass:"u-color--small-text-color u-font--small"},[_vm._v(" "+_vm._s(_vm.transactionDetails.user.addressLandmark ? _vm.transactionDetails.user.addressLandmark : this.$t("TransactionDetailsPageLandmarkNoDataLabel"))+" ")])])]),_c('div',{staticClass:"spacer"}),_c('div',{staticClass:"user-avatar-name u-top-margin--2x u-color--normal-text-color"},[_c('div',{class:`u-avatar u-font--super  ${
            _vm.$store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'
          }`},[_c('i',{staticClass:"el-icon-shopping-cart-2"})]),_c('div',{staticClass:"u-sname-id"},[_c('div',{staticClass:"u-font--bold"},[_vm._v(" "+_vm._s(_vm.transactionDetails.section.selling_type)+" ")]),_c('div',{staticClass:"u-color--small-text-color u-font--small"},[_vm._v(" "+_vm._s(_vm.transactionDetails.section.title)+" ")])])]),(_vm.transactionDetails.user.notes)?[_c('div',{staticClass:"spacer"}),_c('div',{staticClass:"user-avatar-name u-top-margin--2x u-color--normal-text-color"},[_c('div',{class:`u-avatar u-font--super  ${
              _vm.$store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'
            }`},[_c('i',{staticClass:"el-icon-s-order"})]),_c('div',{staticClass:"u-sname-id"},[_c('div',{staticClass:"u-font--small"},[_vm._v(" "+_vm._s(_vm.transactionDetails.user.notes)+" ")])])])]:_vm._e(),(_vm.transactionDetails.notes)?[_c('div',{staticClass:"spacer"}),_c('div',{staticClass:"user-avatar-name u-top-margin--2x u-color--normal-text-color"},[_c('div',{class:`u-avatar u-font--super  ${
              _vm.$store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'
            }`},[_c('i',{staticClass:"el-icon-s-order"})]),_c('div',{staticClass:"u-sname-id"},[_c('div',{staticClass:"u-font--small"},[_vm._v(" "+_vm._s(_vm.transactionDetails.notes)+" ")])])])]:_vm._e(),_c('div',{staticClass:"u-top-margin--2x u-bottom-margin--2x"},[_c('el-collapse',{attrs:{"accordion":""}},[_c('el-collapse-item',{attrs:{"name":"1"}},[_c('template',{slot:"title"},[_c('div',{staticClass:"u-font--bold u-color--normal-text-color"},[_vm._v(" User Note ")])]),_c('div',[_c('text-area-action',{staticStyle:{"margin-top":"1rem"},on:{"check-click":_vm.saveInternalNote},model:{value:(_vm.transactionDetails.user.internalNote),callback:function ($$v) {_vm.$set(_vm.transactionDetails.user, "internalNote", $$v)},expression:"transactionDetails.user.internalNote"}})],1)],2)],1)],1)],2),_c('div',{staticClass:"total-cash u-color--normal-text-color"},[_c('div',{staticClass:"u-font--xsmall"},[_vm._v(" "+_vm._s(_vm.$t("TransactionDetailsPageInfoTotalCashLabel"))+" ")]),_c('div',{staticClass:"u-font--xsmall"},[_c('span',{staticClass:"u-font--large u-font--bold"},[_vm._v(" "+_vm._s(_vm.transactionTotalAmount)+" ")]),_vm._v("  EGP ")])]),_c('div',{staticClass:"user-info"},[_c('div',{staticClass:"u-font--bold u-color--normal-text-color u-top-margin--1x"},[_vm._v(" TRX Note ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.internalNoteValue),expression:"internalNoteValue"}],staticClass:"internal-note-text-area u-top-margin--1x",attrs:{"type":"textarea","rows":"3","placeholder":"Internal Note"},domProps:{"value":(_vm.internalNoteValue)},on:{"keydown":_vm.inputHandler,"input":function($event){if($event.target.composing)return;_vm.internalNoteValue=$event.target.value}}}),_vm._v(" "),_c('div',{staticClass:"u-font--bold"},[_vm._v("Hint: Press enter to save")])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }