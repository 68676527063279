<template>
  <div>
    <el-dialog :title="`Target Details`" :visible.sync="localVisible">
      <template v-if="target && target.id">
        <div class="u-bottom-margin--2x">
          <span style="color: black">Target Type</span>
          <span> : </span>
          <span>{{ target.type }}</span>
        </div>
        <div class="u-bottom-margin--2x">
          <span style="color: black">Target Value</span><span> : </span>
          <span>{{ target.value }}</span>
        </div>
        <div class="u-bottom-margin--2x">
          <span style="color: black">Min. Order</span><span> : </span>
          <span>{{ target.min_order_amount }}</span>
        </div>
        <div class="u-bottom-margin--2x">
          <span style="color: black">Discrepancy</span><span> : </span>
          <span>{{ target.discrepancy }}</span>
        </div>
        <div class="u-bottom-margin--2x">
          <span style="color: black">Payment Method</span><span> : </span>
          <span>{{ target.payment_method }}</span>
        </div>
        <div class="u-bottom-margin--2x">
          <span style="color: black">Sections</span>
          <span> : </span>
          <span>
            <el-tag
              v-for="item in target.sections"
              :key="item.key"
              type="info"
              style="margin: 2.5px"
              size="small"
            >
              {{ item.key }}
            </el-tag>
          </span>
        </div>
        <div class="u-bottom-margin--2x">
          <span style="color: black">Target Conditions</span>
          <span> : </span>
          <span>{{ target.conditions }}</span>
        </div>
        <div class="u-bottom-margin--2x">
          <span style="color: black">Start Date</span>
          <span> : </span>
          <span>{{ target.start_date | moment("DD/MM/YYYY HH:mm:ss") }}</span>
        </div>
        <div class="u-bottom-margin--2x">
          <span style="color: black">End Date</span>
          <span> : </span>
          <span>{{ target.end_date | moment("DD/MM/YYYY HH:mm:ss") }}</span>
        </div>
        <div v-if="providersData">
          <div
            v-if="
              providersData.includedProviders &&
              providersData.includedProviders.length == 0 &&
              providersData.excludedProviders &&
              providersData.excludedProviders.length == 0
            "
          >
            <span style="color: black">Included Suppliers: All </span>
          </div>
          <div
            v-if="
              providersData.includedProviders &&
              providersData.includedProviders.length
            "
          >
            <span style="color: black">Included Suppliers: </span>
            <el-tag
              v-for="item in providersData.includedProviders"
              :key="item.entity_id"
              type="info"
              style="margin: 2.5px"
              size="small"
            >
              <span>{{ item.entity_id }}</span>
              <span> : </span>
              <span>{{ item.name }}</span>
            </el-tag>
          </div>
          <div
            v-if="
              providersData.excludedProviders &&
              providersData.excludedProviders.length
            "
          >
            <span style="color: black">Excluded Suppliers: </span>
            <el-tag
              v-for="item in providersData.excludedProviders"
              :key="item.entity_id"
              type="info"
              style="margin: 2.5px"
              size="small"
            >
              <span>{{ item.entity_id }}</span>
              <span> : </span>
              <span>{{ item.name }}</span>
            </el-tag>
          </div>
        </div>
      </template>
      <span v-else>No Data</span>

      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="localVisible = false">{{
          $t("GlobalOkBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { targetsService } from "@/services/targets.service";

export default {
  name: "TransactionTargetDetailsDialog",

  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      localVisible: false,
      target: null,
      providersData: null,
    };
  },
  watch: {
    localVisible(value) {
      if (value === false) {
        this.target = null;
        this.providersData = null;
        this.$emit("input", null);
        return;
      }
      //   if (value !== oldValue) {
      //     this.$emit("input", value);
      //   }
    },
    async value(newVal) {
      if (newVal) {
        this.getTarget(newVal);
        this.localVisible = true;
      }
    },
  },
  created() {
    this.localVisible = false;
  },
  methods: {
    async getTarget(targetObject) {
      try {
        const trargetId = targetObject.id;
        const filters = {
          id: trargetId,
          // tab: "active"
        };
        const fetchTarget = targetsService.fetchTargets(1, filters);
        const fetchTargetSuppliers =
          targetsService.fetchTargetSupliers(trargetId);
        const response = await Promise.all([fetchTarget, fetchTargetSuppliers]);
        const targetResponseData = response[0].data?.data?.targets;
        let targetData;
        if (targetResponseData.length) {
          targetData = targetResponseData[0];
          this.target = targetData;
        }
        let fetchedProviders = response[1].data?.data;
        if (fetchedProviders) {
          this.providersData = fetchedProviders;
        }

        return response;
      } catch (error) {
        console.log("error :>> ", error);
        const errorMessage = this.$globalFunctions.errorMessageExtractor(error);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 4000);
      }
    },
  },
};
</script>

<style lang="scss"></style>
