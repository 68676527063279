<template>
  <el-card class="box-card u-bottom-margin--2x u-centered">
    <div
      class="back-btn u-color--normal-text-color u-font--bold u-font--medium"
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <span @click="goBack">
        <i class="el-icon-arrow-left"></i>
        {{ $t("TransactionDetailsPageBackBtn") }}
      </span>

      <el-button
        v-if="checkTRXStatus()"
        style="margin-left: auto"
        icon="el-icon-plus"
        type="primary"
        @click="pendingOperationDialog = true"
        size="medium"
        >Request Pending Operation</el-button
      >
    </div>
    <template>
      <el-dialog
        @closed="cancelPendingOperation"
        title="Request Pending Operation"
        :visible.sync="pendingOperationDialog"
      >
        <div>
          <el-form
            :model="pendingOperationFormData"
            :rules="rules"
            ref="pendingOperationForm"
          >
            <el-form-item
              prop="chosenReason"
              label="Select request reason"
              label-width="200px"
            >
              <el-select
                :placeholder="`Request Reasons`"
                clearable
                v-model="pendingOperationFormData.chosenReason"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in pendingOperationReasonsList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer">
          <el-button @click="cancelPendingOperation">{{
            $t("GlobalsCancelBtn")
          }}</el-button>
          <el-button type="success" @click="requestPendingOperation">{{
            $t("GlobalsSaveBtn")
          }}</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- <el-checkbox-group
      border
      v-if="orderDistributers"
      v-model="selectedDistributers"
    >
      <template>
        <div class="distributerContainer">
          <div v-for="(distributer, index) in getBestDistributer" :key="index">
             <el-checkbox
              border
              :label="distributer.id.toString()"
              class="checkbox-item"
              @change="handleDistributersChange"
            >
              <div class="u-display-flex u-align-items--center">
                <span
                  v-if="
                    distributer.areas.includes(transactionDetails.user.area.id)
                  "
                >
                  *
                </span>
                <div
                  :class="`small-circle blacklist ${
                    $store.state.isRTL
                      ? 'u-right-margin--2x'
                      : 'u-left-margin--2x'
                  }`"
                  v-if="distributer.blacklist"
                ></div>
                <div
                  :class="`small-circle original ${
                    $store.state.isRTL
                      ? 'u-right-margin--2x'
                      : 'u-left-margin--2x'
                  }`"
                  v-if="distributer.original"
                ></div>
                <div
                  :class="`small-circle receipt ${
                    $store.state.isRTL
                      ? 'u-right-margin--2x'
                      : 'u-left-margin--2x'
                  }`"
                  v-if="distributer.receipt"
                ></div>
                <span
                  :class="
                    $store.state.isRTL
                      ? 'u-right-margin--1x'
                      : 'u-left-margin--1x'
                  "
                >
                  {{ distributer.name }}</span
                >
              </div>
            </el-checkbox>
          </div>
        </div>
      </template>
    </el-checkbox-group> -->

    <!-- <div
      class="u-direction--rtl u-font--tiny u-color--ink-lightest u-top-margin--1x u-right"
    >
      <span>* {{ $t("TransactionDetailsPageDistributerFromSameArea") }}</span>
    </div>

    <div
      class="u-direction--rtl u-font--tiny u-color--ink-lightest u-right u-display-flex u-align-items--center"
    >
      <div class="small-circle blacklist u-left-margin--1x"></div>
      <span> {{ $t("TransactionDetailsPageBlackListLabel") }} </span>
    </div>
    <div
      class="u-direction--rtl u-font--tiny u-color--ink-lightest u-right u-display-flex u-align-items--center"
    >
      <div class="small-circle original u-left-margin--1x"></div>
      <span> {{ $t("TransactionDetailsPageOriginalLabel") }} </span>
    </div>
    <div
      class="u-direction--rtl u-font--tiny u-color--ink-lightest u-right u-display-flex u-align-items--center"
    >
      <div class="small-circle receipt u-left-margin--1x"></div>
      <span>
        {{ $t("TransactionDetailsPageDistributerReceiptLabel") }}
      </span>
    </div>  -->
  </el-card>
</template>

<script>
import { first, filter, forEach, each, orderBy, find } from "lodash";
import { matchTrxItemToReceiptItem } from "@/services/trxDetailsHelpers";
import { transactions } from "@/services/transactions.service.js";

export default {
  name: "TransactionPossibleDistributers",
  computed: {
    orderDistributers() {
      return JSON.parse(
        JSON.stringify(this.$store.getters.getOrderDistributers)
      );
    },
    transactionDetails() {
      return this.$store.getters.getTransactionDetails;
    },
    selectedDistributersFromStore() {
      return this.$store.getters.getSelectedDistributers;
    },
    productDistirubtersPricesMap() {
      return JSON.parse(
        JSON.stringify(this.$store.getters.getProductDistirubtersPricesMap)
      );
    },
  },
  created() {
    this.selectedDistributers = JSON.parse(
      JSON.stringify(this.selectedDistributersFromStore)
    );
  },
  watch: {
    transactionDetails(newDetails) {
      if (this.transactionDetails?.receipts?.length) {
        this.getTransactionReceiptsItems =
          this.transactionDetails?.receipts.reduce((acc, curr) => {
            if (curr.items.length) {
              return [...curr.items, ...acc];
            }
          }, []);
      } else {
        this.getTransactionReceiptsItems = this.transactionDetails?.items;
      }

      this.getBestDistributer = Object.keys(this.orderDistributers)
        ?.filter((distributer) => {
          let checkHaveSameProduct = this.getTransactionReceiptsItems.some(
            (item) => {
              let findProduct = this.orderDistributers[
                distributer
              ].products.find(
                (product) => product.productId === item.productId
              );

              return !!findProduct;
            }
          );
          return checkHaveSameProduct;
        })
        .map((item) => this.orderDistributers[item]);

      if (newDetails) {
        this.fetchPendingOpsReasons();
      }
    },

    selectedDistributersFromStore: {
      handler(val) {
        this.selectedDistributers = JSON.parse(JSON.stringify(val));
      },
      deep: true,
    },
  },
  data() {
    return {
      pendingOperationReasonsList: [],
      rules: {
        chosenReason: [
          {
            required: true,
            message: "Please Select The Reason",
          },
        ],
      },
      pendingOperationFormData: { chosenReason: undefined },
      selectedDistributers: [],
      pendingOperationDialog: false,
    };
  },
  methods: {
    async fetchPendingOpsReasons() {
      try {
        const response = await transactions.getTransactionPendingOpsReasons();
        if (response && response.status) {
          this.pendingOperationReasonsList = response?.data?.data;
        }
      } catch (error) {
        console.log("fetchPendingOpsReasonsError", error);
      }
    },
    // async fetchNextStatuses() {
    //   try {
    //     const currentStatus = this.transactionDetails.state;
    //     const response = await transactions.getTransactionNextStatus({
    //       currentStatus,
    //       status_type: "transaction",
    //     });
    //     if (response && response.status == 200) {
    //       this.pendingOperationReasonsList = response.data.data.find(
    //         (el) => el.next_status === "pending_operation"
    //       ).nextStatusReasons;
    //     }
    //   } catch (error) {
    //     console.log("fetchNextStatusesError", error);
    //   }
    // },
    cancelPendingOperation() {
      this.pendingOperationDialog = false;
      this.pendingOperationFormData.chosenReason = undefined;
      this.$refs.pendingOperationForm.clearValidate();
    },
    buildPendingOperationFormData() {
      return {
        reason: this.pendingOperationFormData?.chosenReason,
      };
    },
    async requestPendingOperation() {
      this.$refs["pendingOperationForm"].validate(async (isValid) => {
        try {
          const trxId = this.transactionDetails.id;
          const dataToSent = this.buildPendingOperationFormData();
          if (isValid) {
            const response = await transactions.requestPendingOperation(
              trxId,
              dataToSent
            );
            if (response && response.status == 200) {
              await this.$store.dispatch("getLatestData");
              this.$globalFunctions.popupMessageWrapper(
                "Request Created successfully",
                "success",
                1500
              );
              this.pendingOperationDialog = false;
            }
          }
        } catch (error) {
          const errorMessage =
            this.$globalFunctions.errorMessageExtractor(error);
          this.$globalFunctions.popupMessageWrapper(
            errorMessage,
            "error",
            3500
          );
        }
      });
    },
    checkTRXStatus() {
      const inProgressStates = ["in progress", "canceled", "rejected"];
      if (
        inProgressStates.includes(this.transactionDetails.state) &&
        !this.transactionDetails.pendingOperationRequest
      ) {
        return true;
      }
      return false;
    },
    goBack() {
      this.$router.go(-1);
    },
    canDistibuterBeShown(distributer) {
      let shown = true;
      if (this.transactionDetails.section == "direct") {
        shown =
          (distributer.original || distributer.receipt) &&
          !distributer.blacklist;
      }
      if (this.transactionDetails.state != "pending") {
        shown = shown && distributer.receipt;
      }

      return shown;
    },
    handleDistributersChange(isDistributerSelected, { target: { value } }) {
      let receipts = JSON.parse(
        JSON.stringify(this.$store.getters.getTransactionReceipts)
      );
      let isSameDistributer = isDistributerSelected;
      let transctionItems = JSON.parse(
        JSON.stringify(this.$store.getters.getTransactionItems)
      );

      let removedDistributerReceipt = first(
        filter(receipts, (o) => {
          return o.receiptDistributer.id.toString() === value;
        })
      );

      if (!isDistributerSelected && removedDistributerReceipt) {
        forEach(removedDistributerReceipt.items, (item) => {
          let bestPriceItem = [];
          each(
            this.productDistirubtersPricesMap[item.productId],
            (priceValue, distributerIdKey) => {
              if (this.selectedDistributers.indexOf(distributerIdKey) > -1) {
                bestPriceItem.push({
                  distributerId: distributerIdKey,
                  cashPrice: priceValue,
                  productId: item.productId,
                });
              }
            }
          );
          // get lowest price of remaining order distributers
          bestPriceItem =
            first(orderBy(bestPriceItem, ["cashPrice"], ["asc"])) || false;

          const equivalentTransactionItem = find(transctionItems, (trxItem) => {
            return matchTrxItemToReceiptItem(trxItem, item);
          });

          if (bestPriceItem) {
            item.price = bestPriceItem.cashPrice;
            equivalentTransactionItem.price = bestPriceItem.cashPrice;

            item.priceDifference = (item.price - item.originalPrice).toFixed(2);
            item.equivalentTransactionItem = (
              item.price - item.originalPrice
            ).toFixed(2);
            if (item.priceDifference < 0) {
              item.originalPrice = item.price;
              item.priceDifference = 0;
              equivalentTransactionItem.originalPrice = item.price;
              equivalentTransactionItem.priceDifference = 0;
            }
            item.distributerId = parseInt(bestPriceItem.distributerId);
            equivalentTransactionItem.distributerId = parseInt(
              bestPriceItem.distributerId
            );
            item.provider.id =
              this.orderDistributers[bestPriceItem.distributerId].id;
            item.provider.name =
              this.orderDistributers[bestPriceItem.distributerId].name;
            equivalentTransactionItem.provider.id =
              this.orderDistributers[bestPriceItem.distributerId].id;
            equivalentTransactionItem.provider.name =
              this.orderDistributers[bestPriceItem.distributerId].name;
          } else {
            item.price = item.originalPrice; // ignore any modifications done before move to cancel
            item.priceDifference = 0;
            item.distributerId = -1;
            item.provider.id = -1;
            item.provider.name = "Canceled Items";

            equivalentTransactionItem.price = item.originalPrice; // ignore any modifications done before move to cancel
            equivalentTransactionItem.priceDifference = 0;
            equivalentTransactionItem.distributerId = -1;
            equivalentTransactionItem.provider.id = -1;
            equivalentTransactionItem.provider.name = "Canceled Items";
          }
        });
        // }
      } else {
        let addedDistributer = first(
          filter(this.orderDistributers, (o) => {
            return o.id.toString() === value;
          })
        );

        let distributerAssignToAnItem = false;
        let distributerhasNoProductTobeassgiend = true;
        // reassign all products with best prices from original order to new distributer
        // reassign possible canceled items to selected distributer
        forEach(transctionItems, (item) => {
          let distributerProductPrice =
            addedDistributer.productsPriceMap[item.productId];

          if (distributerProductPrice)
            distributerhasNoProductTobeassgiend = false;
          if (
            (distributerProductPrice && item.price > distributerProductPrice) || // reassign to best price
            (item.distributerId === -1 && distributerProductPrice) // reassign posibble canceled items
          ) {
            if (!distributerAssignToAnItem) distributerAssignToAnItem = true;
            if (
              addedDistributer.receipt &&
              item?.offer &&
              item?.offer?.is_active
            ) {
              isSameDistributer = !addedDistributer.receipt;
              item.price = item?.offer?.price;
            } else {
              isSameDistributer = isDistributerSelected;
              item.price = distributerProductPrice;
            }
            item.priceDifference = (item.price - item.originalPrice).toFixed(2);
            if (item.priceDifference < 0) {
              item.originalPrice = item.price;
              item.priceDifference = 0;
            }
            item.distributerId = addedDistributer.id;
            item.provider.id = addedDistributer.id;
            item.provider.name = addedDistributer.name;
          }
        });

        if (!distributerAssignToAnItem && distributerhasNoProductTobeassgiend) {
          this.$alert(
            this.$t("TransactionDetailsNoSutibleProductErrorMessage"),
            this.$t("TransactionDetailsNoSutibleProductErrorTitle"),
            {
              confirmButtonText: this.$t(
                "TransactionDetailsNoSutibleProductErrorOkBtn"
              ),
            }
          );
        } else if (!distributerAssignToAnItem) {
          this.$alert(
            this.$t("TransactionDetailsNotBestPriceProviderErrorMessage"),
            this.$t("TransactionDetailsNotBestPriceProviderErrorTitle"),

            {
              confirmButtonText: this.$t(
                "TransactionDetailsNotBestPriceProviderErrorOkBtn"
              ),
            }
          );
        }
      }
      receipts = JSON.parse(JSON.stringify(receipts));
      this.$store.commit("setTransactionReceipts", receipts);
      this.$store.commit("setIsDistributerSelected", isSameDistributer);
      transctionItems = JSON.parse(JSON.stringify(transctionItems));
      this.$store.commit("setTransactionItems", transctionItems);
      // TODO need to recalculate totalOrderAmount
      this.$store.dispatch("recalculateReceipts");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/wrench-kit/settings/_settings.global.scss";
.distributerContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
}
.checkbox-item::v-deep {
  .el-checkbox__label {
    font-size: 1rem !important;
    font-weight: bold;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $wrench-color-normal-text-color !important;
  }
  .el-checkbox__inner {
    border-radius: 50%;
    padding: 0.2rem;
  }
  border: none !important;
  color: $wrench-color-normal-text-color !important;
  border-radius: 24px !important;
  background-color: #40579312;
  margin-top: 0.5rem;
}
.small-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.blacklist {
  background: rgb(84, 92, 100);
}
.original {
  background: #85ce61;
}
.receipt {
  background: #cb0300;
}
</style>
