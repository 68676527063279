<template>
  <div>
    <el-card
      v-if="
        transactionDetails &&
        transactionDetails.state != 'complete' &&
        transactionDetails.state != 'unfulfilled'
      "
      class="u-top-margin--2x"
      :body-style="{ padding: '13px' }"
    >
      <div v-if="receiptsSaved">
        <el-button
          type="primary"
          slot="reference"
          class="u-font--medium card-btn save-btn"
          @click="saveOrderReceipts"
          >{{ $t("TransactionDetailsPageInfoSaveReceiptsBtn") }}</el-button
        >
      </div>
      <div v-else>
        <el-button
          type="primary"
          slot="reference"
          class="u-font--medium card-btn save-btn"
          @click="createReceipts"
          >{{ `Create Receipts` }}</el-button
        >
      </div>
      <div>
        <!-- <el-button
        class="u-font--medium card-btn progress-btn"
        v-if="showChangeStatus && nextStatus"
        @click="changeTransactionStatus"
        >{{ $t("TransactionDetailsPageInfoChangeStatusBtn") }}
        {{ nextStatus | capitalize }}</el-button
      > -->
        <el-button
          class="u-font--medium card-btn progress-btn"
          @click="showChangeStatusDialog = true"
          >{{ `Change Status` }}</el-button
        >
      </div>
    </el-card>
    <TransactionChangeStatusDialog v-model="showChangeStatusDialog" />

    <el-dialog :title="`Create Receipt`" :visible.sync="localVisible">
      <div>
        <el-radio-group v-model="chosenReason">
          <template>
            <el-radio
              v-for="item in possibleReasons"
              :key="item"
              class="u-bottom-margin--2x"
              style="display: block"
              :label="item"
              >{{ item }}
            </el-radio>
          </template>
        </el-radio-group>
        <el-input
          v-if="possibleReasons.length > 0"
          v-model="textComment"
          autocomplete="off"
          :placeholder="`Comment`"
        ></el-input>
      </div>
      <span slot="footer">
        <el-button @click="localVisible = false">{{
          $t("GlobalsCancelBtn")
        }}</el-button>
        <el-button type="success" @click="saveClicked">{{
          $t("GlobalsSaveBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TransactionChangeStatusDialog from "./TransactionChangeTransactionStatusDialog.vue";

import { transactions } from "@/services";

export default {
  name: "TransactionActionButtons",
  components: {
    TransactionChangeStatusDialog,
  },
  data() {
    return {
      showChangeStatusDialog: false,
      localVisible: false,
      chosenReason: null,
      possibleReasons: [],
      textComment: "",
    };
  },
  computed: {
    transactionDetails() {
      return this.$store.getters.getTransactionDetails;
    },
    // showChangeStatus() {
    //   if (
    //     ["in progress", "processed"].includes(this.transactionDetails.state)
    //   ) {
    //     return true;
    //   }
    //   return false;
    // },
    // nextStatus() {
    //   if (["neverShow"].includes(this.transactionDetails.state)) {
    //     return "in progress";
    //   } else if (this.transactionDetails.state) {
    //     return "complete";
    //   } else {
    //     return false;
    //   }
    // },
    receiptsSaved() {
      if (this.transactionDetails.receipts.length) {
        return true;
      } else {
        return false;
      }
    },
  },
  // filters: {
  //   capitalize: (val) => {
  //     return val.charAt(0).toUpperCase() + val.slice(1);
  //   },
  // },
  methods: {
    async createReceipts() {
      this.localVisible = true;
      // await this.saveOrderReceipts();
    },
    async saveOrderReceipts() {
      try {
        const response = await this.$store.dispatch("saveReceipts");
        if (response && response.status == 200) {
          await this.$store.dispatch("getLatestData");
        }
      } catch (error) {
        console.log("saveOrderReceipts error :>> ", error);
        this.$message({
          message: this.$t("GlobalErrorMessage"),
          type: "error",
          duration: 2000,
        });
        this.$store.dispatch("getLatestData");
        this.updateReceiptsErrorHandler(error);
      }
    },
    async changeTransactionStatus() {
      if (!this.receiptsSaved) {
        this.$notify({
          type: "warning",
          title: this.$t(
            "TransactionDetailsChangeTransactionStatusSaveValidationNotificationTitle"
          ),
          message: this.$t(
            "TransactionDetailsChangeTransactionStatusSaveValidationNotificationMessage"
          ),
        });
        return;
      }

      this.$confirm(
        this.$t("TransactionDetailsChangeTransactionStatusPromptMessage"),
        this.$t("TransactionDetailsChangeTransactionStatusPromptTitle"),
        {
          confirmButtonText: this.$t(
            "TransactionDetailsChangeTransactionStatusPromptConfirmBtn"
          ),
          cancelButtonText: this.$t(
            "TransactionDetailsChangeTransactionStatusPromptCancelBtn"
          ),
        }
      ).then(async () => {
        try {
          const response = await this.$store.dispatch(
            "updateTrxStatus",
            this.nextStatus
          );
          if (response && response.status == 200) {
            this.$message.success(
              this.$t("TransactionDetailsChangeTransactionStatusSucessMessage")
            );
            await this.$store.dispatch("getLatestData");
          }
        } catch (error) {
          console.log("changeTransactionStatus error :>> ", error);
          this.$message.error(
            this.$t("TransactionDetailsChangeTransactionStatusErrorMessage")
          );
        }
      });
    },
    updateReceiptsErrorHandler(errorObject) {
      const responseObject = errorObject.response;
      let htmlStringOutput = `<div><ul>`;
      if (responseObject.data && responseObject.data.data) {
        const errorsData = responseObject.data.data;
        for (let key of Object.keys(errorsData)) {
          errorsData[key].forEach((item) => {
            htmlStringOutput += `<li>
            <span>${item}</span>
          </li>`;
          });
        }
      }
      htmlStringOutput += ` </ul></div> `;
      this.$alert(htmlStringOutput, "Save Receipt Error", {
        dangerouslyUseHTMLString: true,
        lockScroll: false,
      });
    },
    async saveClicked() {
      if (!this.chosenReason) {
        this.$globalFunctions.popupMessageWrapper(
          `Please select a reason`,
          "error",
          2000
        );
        return;
      }
      const reasonBody = {
        reason: this.chosenReason,
        comment: this.textComment,
      };
      try {
        const response = await this.$store.dispatch("saveReceipts", reasonBody);
        this.clearPopupData();
        if (response && response.status == 200) {
          await this.$store.dispatch("getLatestData");
        }
      } catch (error) {
        console.log("saveOrderReceipts error :>> ", error);
        this.$message({
          message: this.$t("GlobalErrorMessage"),
          type: "error",
          duration: 2000,
        });
        this.$store.dispatch("getLatestData");
        this.updateReceiptsErrorHandler(error);
      }
    },
    async getPossibleReasonsForInProgress() {
      const filter = {
        status_type: "transaction",
        status: "in progress",
      };
      try {
        const response = await transactions.getTransactionStatusReasons(filter);
        const reasons = response?.data?.data?.map((item) => item.reason) || [];
        this.possibleReasons = reasons;
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    clearPopupData() {
      this.localVisible = false;
      this.chosenReason = null;
      this.textComment = null;
    },
  },
  watch: {
    transactionDetails(value) {
      if (value && (value.state == "pending" || value.state == "pending_operation")) {
        this.getPossibleReasonsForInProgress();
      }
    },
    localVisible(value) {
      if (value == false) {
        this.chosenReason = null;
        this.textComment = null;
      }
    },
  },
};
</script>

<style></style>
