<template>
  <div class="c-transaction-details-container">
    <el-row :gutter="20">
      <el-col :span="18" :offset="0">
        <TransactionPossibleDistributers />

        <el-card :body-style="{ padding: '20px' }">
          <div class="reciept-container">
            <TransactionSummary />
          </div>
          <div>
            <TransactionReceiptsHolder />
          </div>
        </el-card>
      </el-col>
      <el-col :span="6" :offset="0">
        <TransactionMeta />
        <TransactionActionButtons class="u-top-margin--2x" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { includes } from "lodash";
import TransactionMeta from "./TransactionMeta.vue";
import TransactionActionButtons from "./TransactionActionButtons.vue";
import TransactionPossibleDistributers from "./TransactionPossibleDistributers.vue";
import TransactionSummary from "./TransactionSummary.vue";
import TransactionReceiptsHolder from "./TransactionReceiptsHolder.vue";
export default {
  name: "TransactionDetails",
  components: {
    TransactionPossibleDistributers,
    TransactionMeta,
    TransactionActionButtons,
    TransactionSummary,
    TransactionReceiptsHolder,
  },
  data() {
    return {
      productDistirubtersPricesMap: {},
      canPrint: true,
      enablePrintTimer: {},
      receiptsSaved: false,
      transactionDetails: {},
      selectedDistributers: [],
      orderDistributers: {},
      receipts: [],
      originalReceipts: [],
      cancelledOrderProps: {},
      creditConfig: {},
      transactionReceiptsSaveInprogress: false,
      isPrintLoading: false,
      autoAssignmentDialogFlag: false,
    };
  },
  computed: {
    transactionId() {
      return this.$route.params.id;
    },

    showChangeStatus() {
      if (
        includes(["in progress", "processed"], this.transactionDetails.state)
      ) {
        return true;
      }
      return false;
    },
    nextStatus() {
      //keep the old logic for future states
      if (includes(["neverShow"], this.transactionDetails.state)) {
        return "in progress";
      } else if (this.transactionDetails.state) {
        return "complete";
      } else {
        return false;
      }
    },
    trxFromVuex() {
      return this.$store.getters.getTransactionDetails;
    },
  },
  async mounted() {
    this.$store.commit("clearTrxData");
    this.$store.commit("setTransactionId", this.transactionId);
    // const transactionIdFromStore = this.$store.getters.getTransactionId;
    // if (!transactionIdFromStore) {

    // }
    await this.$store.dispatch("fetchTrxDetailsData", this.transactionId);
  },
};
</script>

<style lang="scss">
/* global styles */
.el-message-box__btns {
  direction: rtl;

  button:nth-child(2) {
    margin-left: 0;
  }

  button:nth-child(1) {
    margin-left: 10px;
  }
}
</style>
<style lang="scss" scoped>
@import "@/styles/wrench-kit/settings/_settings.global.scss";

$right-card-bg-color: #ffc1442e;
.checkbox-item::v-deep {
  .el-checkbox__label {
    font-size: 1rem !important;
    font-weight: bold;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $wrench-color-normal-text-color !important;
  }
  .el-checkbox__inner {
    border-radius: 50%;
    padding: 0.2rem;
  }
  border: none !important;
  color: $wrench-color-normal-text-color !important;
  border-radius: 24px !important;
  background-color: #40579312;
  margin-top: 0.5rem;
}
.spacer {
  height: 1px;
  background: #abb5d0;
  // border: 1px solid #abb5d0;
  opacity: 0.25;
  margin: 1rem 0rem;
}
.c-distributers-header {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.c-distributers-header__title {
  margin-right: auto;
  font-size: 24px;
  font-weight: bold;
}
.small-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.blacklist {
  background: rgb(84, 92, 100);
}
.original {
  background: #85ce61;
}
.receipt {
  background: #cb0300;
}

//Whole page container
.c-transaction-details-container::v-deep {
  .back-btn {
    text-align: left;
    span {
      cursor: pointer;
    }
  }

  .el-page-header__content {
    width: 100%;
  }

  .t-coulumn {
    border-radius: 4px;
    color: $wrench-color-normal-text-color !important;
    font-weight: bold;
    font-size: 0.75rem;
    background-color: #f5f6fa !important;

    &:hover {
      background-color: #f5f6fa !important;
    }
  }

  .el-card {
    border: none;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 4px 4px 0 rgba(43, 45, 55, 0.01);
  }
  .el-collapse-item__header {
    height: auto;
  }
  [class^="reciept-container"] {
    .el-collapse {
      border: none;
      .el-collapse-item__header {
        padding: 2rem;
      }
      //receipt-card-title
      .c-header-title {
        color: $wrench-color-normal-text-color;
      }
      .c-reciept-header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        .c-header-tags {
          display: flex;
          flex-wrap: wrap;
        }
        .right-container {
          flex-basis: 15%;
          flex-direction: row-reverse;
        }

        [class^="header-tag"] {
          line-height: 19px;

          border-radius: 6px;
          color: #ffffff;
          padding: 1px 9px;
          font-weight: 900;
          margin-right: 0.4rem;
          margin-top: 0.4rem;
          background-color: #545c64;
        }
        [class*="--red"] {
          background-color: #ff595e;
        }
        [class*="--green"] {
          background-color: #0dad2f;
        }
        [class*="--blue"] {
          background-color: #5977ff;
        }
        [class*="--valid"] {
          color: $wrench-color-normal-text-color;
          background-color: #8ac92663;
          padding: 5px;

          font-size: 1.13rem;
          box-shadow: 0 5px 4px 0 rgba(43, 45, 55, 0.03);
        }
        [class*="--unvalid"] {
          background-color: #ff595e;
          padding: 5px;

          font-size: 1.13rem;
          box-shadow: 0 5px 4px 0 rgba(43, 45, 55, 0.03);
        }
      }
      .card-body {
        padding: 30px;
        //before table
        .c-fatura-card__header-buttons {
          .steal-btn {
            flex-basis: 25%;
            .el-button {
              width: 100%;
              justify-content: space-between;
            }
          }
          margin-left: auto;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        table {
          border-spacing: 5px;
          border-collapse: separate;
        }
      }
    }

    //reciecpt container styling
    border: none;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0);
    border-left: 15px solid #fccd13;
    margin: 1rem;
    box-shadow: 0 0 32px 0 rgba(12, 10, 61, 0.04);
  }
  //user reciept item
  [class*="--item"] {
    border-left: 15px solid #545c64;
  }
  //***************************************************************************** */
  //*********** */ transaction infomations*********************************************
  //***************************************************************************** */
  .transaction-info {
    display: flex;
    padding: 11px 15px;
    align-items: center;
    background-color: $right-card-bg-color;

    .t-number {
      color: #ffffff;
      padding: 2px 6px;
      border-radius: 4px;
      margin-right: 1rem;
    }
  }
  .user-info {
    padding: 0px 25px;
    .user-avatar-name {
      display: flex;
      align-items: center;
      .u-avatar {
        border-radius: 14px;

        padding: 8px 15px;
        background-color: $right-card-bg-color;
      }
    }
    .u-name-phone {
      text-align: center;
    }
    .user-area {
      text-align: center;
      font-weight: 600;
      color: #545c64;
    }
  }
  .total-cash {
    background-color: $right-card-bg-color;
    padding: 1em;
    text-align: center;
  }

  .card-btn {
    width: 100%;
    font-weight: 900 !important;
    padding: 13px 0px;
    // background-color: $right-card-bg-color;
  }
  .save-btn {
    color: #545c64;
  }
  .progress-btn {
    background-color: #545c64;
    color: #ffffff;
    margin-top: 0.75rem;
  }
}

// differant direction styles
[dir="rtl"] {
  .reciept-container {
    border: none;
    border-right: 15px solid #fccd13;
  }
  .reciept-container--item {
    border: none;
    border-right: 15px solid #545c64;
  }

  .t-number {
    margin-right: auto;
    margin-left: 1rem;
  }
  .back-btn {
    text-align: right;
  }
  .el-icon-arrow-left {
    transform: rotateY(180deg);
  }
}
</style>
