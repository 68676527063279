<template>
  <div>
    <el-collapse accordion v-if="transactionDetails">
      <el-collapse-item name="1">
        <template slot="title">
          <div class="c-reciept-header">
            <div>
              <div class="c-header-title u-font--large u-font--bold">
                <span>{{ $t("TransactionDetailsPageOrderSummaryTitle") }}</span>
                <span
                  v-if="
                    transactionDetails.isAutoAssigned &&
                    transactionDetails.isAutoAssigned == 1 &&
                    transactionDetails.state == 'pending'
                  "
                >
                  <el-button
                    size="mini"
                    type="primary"
                    :class="
                      $store.state.isRTL
                        ? 'u-right-margin--1x'
                        : 'u-left-margin--1x'
                    "
                    @click="stopAutoAssignmentClicked"
                    style="border: none"
                    >{{
                      $t("TransactionDetailsPageStopAutoAssignmentButtonLabel")
                    }}</el-button
                  ></span
                >
              </div>
              <div class="c-header-tags">
                <div
                  v-if="transactionDetails?.sourceIdentifier"
                  class="header-tag--green"
                >
                  {{ transactionDetails?.sourceIdentifier }}
                </div>
                <div v-if="transactionDetails.method" class="header-tag">
                  {{ $t("TransactionDetailsPageHeaderTagPayingLabel") }}
                  {{ transactionDetails.method }}
                </div>
                <div v-if="transactionDetails.bundle" class="header-tag">
                  {{ $t("TransactionDetailsPageHeaderTagDurationLabel") }}
                  {{ transactionDetails.bundle.paybackDuration }}
                </div>
                <div v-if="transactionDetails.bundle" class="header-tag">
                  {{ $t("TransactionDetailsPageHeaderTagPremiumLabel") }}
                  {{ transactionDetails.bundle.premiumPercentage * 100 }}
                </div>
                <div
                  v-if="
                    transactionDetails.totalAmount >
                    transactionDetails.totalOriginalAmount
                  "
                  class="header-tag"
                >
                  {{
                    $t("TransactionDetailsPageHeaderTagPriceDifferenceLabel")
                  }}
                  {{
                    (
                      transactionDetails.totalAmount -
                      transactionDetails.totalOriginalAmount
                    ).toLocaleString()
                  }}
                  EGP
                  <span class="u-font--bold u-font--medium"
                    >({{
                      (((transactionDetails.totalAmount -
                        transactionDetails.totalOriginalAmount) /
                        transactionDetails.totalOriginalAmount) *
                        100)
                        | formatFloatValues
                    }}%)</span
                  >
                </div>
                <div class="header-tag" v-if="transactionDetails.items">
                  {{
                    $t("TransactionDetailsPageHeaderTagItemsLengthLabel", [
                      transactionDetails.items.length,
                    ])
                  }}
                </div>
                <div
                  v-if="cancelledOrderProps.cancelledItemsCount"
                  class="header-tag--red"
                >
                  {{ $t("TransactionDetailsPageHeaderTagCancelationAmount") }}

                  {{ cancelledOrderProps.amount.toLocaleString() }} EGP ({{
                    cancelledOrderProps.amountPercentge | formatFloatValues
                  }}%)
                </div>
                <div
                  v-if="transactionDetails.promocode"
                  class="header-tag--green"
                >
                  {{ $t("TransactionDetailsPageHeaderTagPromoLabel") }}
                  {{ transactionDetails.promocode.code }}
                </div>
                <div
                  v-if="transactionDetails.promocode"
                  class="header-tag--green"
                >
                  {{ transactionDetails.promocode.discount_amount }} EGP
                </div>
                <div
                  v-if="transactionDetails.cashbackRedemptionAmount"
                  class="header-tag--red"
                >
                  Cashback: {{ transactionDetails.cashbackRedemptionAmount }}
                </div>
                <!-- Billing Details -->
                <template v-if="transactionDetails.billingDetails">
                  <div class="header-tag--red">
                    {{ transactionDetails.billingDetails[0].text }} :
                    {{ transactionDetails.billingDetails[0].value }}
                  </div>

                  <div class="header-tag--green">
                    {{ transactionDetails.billingDetails[2].text }} :
                    {{ transactionDetails.billingDetails[2].value }}
                  </div>
                </template>

                <div
                  v-if="
                    transactionDetails.isAutoAssigned &&
                    transactionDetails.isAutoAssigned == 1
                  "
                  class="header-tag--blue"
                >
                  {{ $t("TransactionDetailsPageHeaderTagAutoAssignedLabel") }}
                </div>

                <template v-if="transactionDetails.offersDetails.length">
                  <div class="header-tag">
                    {{
                      `Product Offer SKUs %: ${transactionDetails.offersDetails[0].offersCountPercent}`
                    }}
                  </div>
                  <div class="header-tag">
                    {{
                      `Product Offer Value %: ${transactionDetails.offersDetails[0].amountPercent}`
                    }}
                  </div>
                </template>

                <div
                  class="header-tag--red"
                  v-if="transactionDetails?.pendingOperationRequest"
                >
                  Pending Operation Reason 🧨:
                  {{ transactionDetails?.pendingOperationRequestReason }}
                </div>
              </div>
            </div>

            <div class="u-display-flex">
              <div class="header-tag--valid">
                {{
                  $t("TransactionDetailsPageHeaderTagTotalCashLabel", [
                    transactionTotalAmount,
                  ])
                }}
                <span class="u-font--small u-font--normal"> EGP</span>
              </div>
              <div
                v-if="
                  transactionCreditTotalAmount &&
                  transactionDetails.method == `credit`
                "
                class="header-tag"
              >
                {{
                  $t("TransactionDetailsPageHeaderTagTotalCreditLabel", [
                    transactionCreditTotalAmount,
                  ])
                }}
                EGP
              </div>
            </div>
          </div>
        </template>
        <div class="card-body">
          <div
            class="not-auto-assigned-list"
            v-if="
              transactionDetails.auto_assignment_failure_reasons &&
              transactionDetails.auto_assignment_failure_reasons.length > 0
            "
          >
            <div>
              {{
                $t(
                  "TransactionDetailsPageSummaryAutoAssignmentFailureListTitle"
                )
              }}
            </div>
            <ol>
              <li
                v-for="item in transactionDetails.auto_assignment_failure_reasons"
                :key="item"
              >
                {{ item }}
              </li>
            </ol>
          </div>
          <el-table
            :data="transactionItems"
            style="width: 100%"
            row-class-name="no-hover "
            cell-class-name="t-coulumn"
          >
            <el-table-column
              prop="product.name"
              :label="
                $t('TransactionDetailsPageSummaryProductDetailsColumnTitle')
              "
              width="360"
            >
              <template slot-scope="scope">
                <div class="u-display-flex u-align-items--center">
                  <div class="u-right-margin--2x">
                    <!-- <img
                      width="50"
                      height="50"
                      style="object-fit: scale-down"
                      :src="scope.row.product.image"
                    /> -->
                    <div
                      :style="{
                        'background-image':
                          'url(' + scope.row.product.image + ')',
                      }"
                      class="product-image"
                    ></div>
                    <div class="old-ribbon" v-if="scope.row.offer">
                      {{
                        scope.row.offer.type == "hot_offer"
                          ? "Hot offer"
                          : "Flash offer"
                      }}
                    </div>
                  </div>
                  <div class="u-direction--rtl">
                    <div>
                      {{ scope.row.product.name }}
                    </div>
                    <div class="u-display-flex u-direction--ltr">
                      <div
                        class="u-right-padding--half-x u-color--small-text-color"
                      >
                        {{ scope.row.unit.name }}
                      </div>
                      <div class="u-direction--rtl u-color--small-text-color">
                        <span
                          v-if="
                            scope.row.productUnit &&
                            scope.row.productUnit.itemsPerUnit
                          "
                          >{{ scope.row.productUnit.itemsPerUnit }}</span
                        >
                        <span v-else>{{ scope.row.product.itemsPerUnit }}</span>
                      </div>
                    </div>
                    <div class="u-font--xsmall u-color--small-text-color">
                      ID:
                      <strong>{{ scope.row.productId }}</strong>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="originalQuantity"
              :label="$t('TransactionDetailsPageSummaryQuantityColumnTitle')"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="originalPrice"
              :label="$t('TransactionDetailsPageSummaryPriceColumnTitle')"
              width="100"
            >
              <template slot-scope="scope">
                <div
                  class="u-display-flex u-align-items--center"
                  v-if="scope.row.offer"
                  style="text-decoration: line-through"
                >
                  {{ scope.row.offer.original_price }}
                </div>
                <div class="u-display-flex u-align-items--center">
                  {{ scope.row.originalPrice }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="provider.name"
              :label="
                $t('TransactionDetailsPageSummaryProviderNameColumnTitle')
              "
            ></el-table-column>
            <el-table-column prop="item" label="">
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.isFaturaProduct && scope.row.isFaturaProduct == 1
                  "
                >
                  {{ $t("TransactionDetailsPageSummaryFaturaProductLabel") }}
                  <emoji
                    style="padding-left: 5px"
                    :emoji="{ id: 'star', skin: 1 }"
                    :size="15"
                  />
                </div>
                <!-- <div v-if=" scope.row.isOffer || scope.row.is_offer">
                  <img
                    src="../../../../assets/offers.jpg"
                    class="offer-flag"
                    alt="offer"
                  />
                  <img
                    src="../../../../assets/Offers2.png"
                    class="offer-flag"
                    alt="offer"
                  />
                </div> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-collapse-item>
    </el-collapse>
    <TransactionDetailsAutoAssignmentCancellationDialog
      v-model="autoAssignmentDialogFlag"
      :transactionId="transactionId"
      @autoAssignmentStopped="autoAssignmentStopped"
    />
  </div>
</template>

<script>
import TransactionDetailsAutoAssignmentCancellationDialog from "../TransactionDetailsAutoAssignmentCancellationDialog.vue";
import { Emoji } from "emoji-mart-vue";

export default {
  name: "TransactionSummary",
  components: {
    TransactionDetailsAutoAssignmentCancellationDialog,
    Emoji,
  },
  filters: {
    formatFloatValues: function (value) {
      if (!value) return "";
      let filteredValue = value.toFixed(1);
      let output = 0;
      if (parseFloat(filteredValue) > 0) {
        output = filteredValue;
      }
      return output.toString();
    },
  },
  data() {
    return {
      // cancelledOrderProps: {},
      autoAssignmentDialogFlag: false,
    };
  },
  computed: {
    transactionDetails() {
      return this.$store.getters.getTransactionDetails;
    },
    transactionTotalAmount() {
      return this.$store.getters.getTransactionTotalAmount;
    },
    transactionCreditTotalAmount() {
      return this.$store.getters.getTransactionCreditTotalAmount;
    },
    transactionTotalOriginalAmount() {
      return this.$store.getters.getTransactionTotalOriginalAmount;
    },
    transactionCreditTotalOriginalAmount() {
      return this.$store.getters.getTransactionCreditTotalOriginalAmount;
    },
    transactionId() {
      return this.$route.params.id;
    },
    cancelledOrderProps() {
      return this.$store.getters.getCancelledOrderProps;
    },
    transactionItems() {
      return this.$store.getters.getTransactionItems;
    },
  },
  methods: {
    stopAutoAssignmentClicked() {
      this.autoAssignmentDialogFlag = true;
    },
    async autoAssignmentStopped() {
      this.autoAssignmentDialogFlag = false;
      // await this.getTransactionDetails();
      this.$store.dispatch("fetchTrxDetailsData", this.transactionId);
    },
    async moveTransactionToPendingOperation() {
      this.$confirm(
        this.$t("TransactionDetailsChangeTransactionStatusPromptMessage"),
        this.$t("TransactionDetailsChangeTransactionStatusPromptTitle"),
        {
          confirmButtonText: this.$t(
            "TransactionDetailsChangeTransactionStatusPromptConfirmBtn"
          ),
          cancelButtonText: this.$t(
            "TransactionDetailsChangeTransactionStatusPromptCancelBtn"
          ),
        }
      ).then(async () => {
        try {
          const response = await this.$store.dispatch(
            "updateTrxStatus",
            "pending_operation"
          );
          if (response && response.status == 200) {
            this.$message.success(
              this.$t("TransactionDetailsChangeTransactionStatusSucessMessage")
            );
            await this.$store.dispatch("getLatestData");
          }
        } catch (error) {
          console.log("changeTransactionStatus error :>> ", error);
          this.$message.error(
            this.$t("TransactionDetailsChangeTransactionStatusErrorMessage")
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.not-auto-assigned-list {
  font-size: 1rem !important;
}
.offer-flag {
  width: 80px;
  // height: 80px;
}
.old-ribbon {
  position: absolute;
  top: 5px;
  width: 50px;
  height: 10px;
  padding: 2px;
  // margin-left: 5px;
  border-radius: 2px 0px 0px 2px;
  background-color: #ea4335;
  color: white;
  // display: inline-block;
  font-size: 8px;
  // margin-top: 10px;
  line-height: 5px;
}

.old-ribbon::before {
  content: "";
  display: block;
  position: absolute;
  right: -5px;
  top: 0;
  border: solid 5px #ea4335;
  border-left-width: 0;
  border-right-color: transparent;
}

.product-image {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  position: relative;
}
</style>
