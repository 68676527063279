<template>
  <div>
    <el-dialog
      :title="$t('TransactionDetailsWholesalerInfoDialogLabel')"
      :visible.sync="localVisible"
    >
      <div v-if="wholesalerInfoObject">
        <div class="u-bottom-margin--2x">
          <span>{{
            $t("TransactionDetailsWholesalerInfoDialogName", [
              wholesalerInfoObject.name,
            ])
          }}</span>
        </div>
        <div class="u-bottom-margin--2x">
          <span>Wholesaler ID: {{ wholesalerInfoObject.id }}</span>
        </div>
        <div class="u-bottom-margin--2x">
          <span>Delivery Time: {{ deliveryTime }} Days</span>
        </div>
        <div class="u-bottom-margin--2x">
          {{ $t("TransactionDetailsWholesalerInfoDialogContactInfo") }}
          <span>{{ wholesalerInfoObject.phone }}</span>
          <span v-if="wholesalerInfoObject.secondPhone">
            - {{ wholesalerInfoObject.secondPhone }}</span
          >
          <span v-if="wholesalerInfoObject.landline">
            - {{ wholesalerInfoObject.landline }}</span
          >
        </div>
        <div class="u-bottom-margin--2x">
          {{ $t("TransactionDetailsWholesalerInfoDialogProductCategory") }}
          <span v-if="wholesalerInfoObject.productsCategory">{{
            wholesalerInfoObject.productsCategory
          }}</span>
        </div>
        <!-- <div class="u-bottom-margin--2x">
          <span style="color: black">{{
            $t("TransactionDetailsWholesalerInfoDialogWorkingHours")
          }}</span>
          <b></b>
        </div> -->
        <el-row type="flex" justify="start">
          <el-col :span="12">
            <el-tabs v-model="activeTabName" @tab-click="tabClicked" stretch>
              <el-tab-pane
                v-for="item in navigationItems"
                :label="item.tabLabel"
                :name="item.tabName"
                :key="item.tabLabel"
              ></el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
        <div v-if="activeTabName === 'workingDays'">
          <el-table
            :data="wholesalerInfoObject.entityWorkingHours"
            border
            stripe
          >
            <el-table-column
              prop="day"
              :label="
                $t('TransactionDetailsWholesalerInfoDialogTableColumnDays')
              "
            />
            <el-table-column
              prop="opening_hour"
              :label="
                $t('TransactionDetailsWholesalerInfoDialogTableColumnFrom')
              "
            >
              <template slot-scope="scope">
                <div>
                  {{ timeFormatter(scope.row.opening_hour) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="closing_hour"
              :label="$t('TransactionDetailsWholesalerInfoDialogTableColumnTo')"
            >
              <template slot-scope="scope">
                <div>{{ timeFormatter(scope.row.closing_hour) }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="is_open"
              :label="
                $t(
                  'TransactionDetailsWholesalerInfoDialogTableColumnAvailability'
                )
              "
            >
              <template slot-scope="scope">
                <div class="u-text--center">
                  <el-checkbox
                    v-model="scope.row.is_open"
                    disabled
                    :true-label="1"
                    :false-label="0"
                  />
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="activeTabName === 'usersProfile'">
          <el-table :data="providerUsers" border stripe>
            <el-table-column prop="userName" label="Name" />
            <el-table-column prop="userPhone" label="Phone"> </el-table-column>
            <el-table-column prop="secondPhone" label="Second Phone" />
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="localVisible = false">{{
          $t("GlobalOkBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { providerAreaServices } from "@/services/providerArea.service";
import { providersService } from "@/services/providers.service";
import { utils } from "@/utils";

const navigationItems = [
  {
    tabLabel: "Working Days",
    tabName: "workingDays",
  },
  {
    tabLabel: "Users Profile",
    tabName: "usersProfile",
  },
];
export default {
  name: "WholesalerInfoDialog",
  props: ["value", "wholesalerInfoObject", "userAreaId"],
  data() {
    return {
      localVisible: null,
      navigationItems: navigationItems,
      providerAreasList: [],
      areaId: "",
      deliveryTime: 0,
      activeTabName: "workingDays",
      providerUsers: [],
    };
  },
  watch: {
    localVisible(value, oldValue) {
      if (value !== oldValue) {
        this.$emit("input", value);
      }
    },
    value(newVal) {
      this.localVisible = newVal;
    },
    wholesalerInfoObject(data) {
      const whsId = data.id;
      this.getProviderArea(1, whsId);
      this.getProviderUsers(whsId);
    },
  },
  created() {
    this.localVisible = this.value;
    this.areaId = this.userAreaId;
  },
  methods: {
    timeFormatter(value) {
      if (value == 12) {
        return `12 PM`;
      }
      if (value == 0) {
        return `12 AM`;
      }
      if (value > 12) {
        return `${value - 12} PM`;
      } else {
        return `${value} AM`;
      }
    },
    getProviderArea(page = 1, whsId) {
      const filters = {
        area: this.areaId,
        entityId: whsId,
      };
      this.$loading();
      providerAreaServices
        .fetchProviderAreas(page, filters)
        .then((res) => {
          res.providerAreasList.forEach((element) => {
            this.deliveryTime =
              element.delivery_time.calculated_delivery_time ?? 0;
          });
        })
        .catch((error) => {
          const errorMessage = utils.errorMessageExtractor(error);
          utils.popupMessageWrapper(errorMessage, "error", 3500);
          this.providerAreasList = [];
        })
        .finally(() => {
          this.$loading().close();
        });
    },
    tabClicked(eventObject) {
      this.activeTabName = eventObject.name;
    },
    async getProviderUsers(whsId) {
      try {
        let res = await providersService.getProviderUsers(whsId);
        this.providerUsers = res.retailerUsers.filter(
          (user) => user.userActiveFlag
        );
      } catch (error) {
        const errorMessage = utils.errorMessageExtractor(error);
        utils.popupMessageWrapper(errorMessage, "error", 3500);
      }
    },
  },
};
</script>

<style></style>
