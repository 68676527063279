<template>
  <div v-if="receipts">
    <div
      v-for="(receipt, key) in receipts"
      :key="key"
      class="reciept-container--item"
    >
      <el-collapse accordion>
        <el-collapse-item name="1">
          <template slot="title">
            <div class="c-reciept-header">
              <div>
                <div class="c-header-title u-font--large u-font--bold">
                  {{
                    receipt.receiptDistributer &&
                    receipt.receiptDistributer.name
                      ? receipt.receiptDistributer.name
                      : "null"
                  }}
                </div>
                <div class="c-header-tags">
                  <div v-if="receipt.receiptId" class="header-tag">
                    {{
                      $t("TransactionDetailsPageReceiptIdTag", [
                        receipt.receiptId,
                      ])
                    }}
                  </div>

                  <div
                    v-if="
                      transactionTotalAmount > transactionTotalOriginalAmount
                    "
                    class="header-tag"
                  >
                    {{
                      $t(
                        "TransactionDetailsPageReceiptHeaderTagPriceDifferenceLabel"
                      )
                    }}
                    {{
                      (
                        transactionTotalAmount - transactionTotalOriginalAmount
                      ).toLocaleString()
                    }}
                    EGP
                    <span class="u-font--bold u-font--medium"
                      >({{
                        (((transactionTotalAmount -
                          transactionTotalOriginalAmount) /
                          transactionTotalOriginalAmount) *
                          100)
                          | formatFloatValues
                      }}%)</span
                    >
                  </div>
                  <div v-if="receipt.receiptStatus" class="header-tag">
                    {{ receipt.receiptStatus }}
                  </div>
                  <div v-if="receipt.undeliver_reason" class="header-tag">
                    {{
                      $t("TransactionDetailsPageReceiptUnDeliverReasonLabel", [
                        receipt.undeliver_reason,
                      ])
                    }}
                  </div>

                  <div
                    v-if="receipt.validate_undeliver_reason"
                    class="header-tag"
                  >
                    Validated Undelivered Reason :
                    {{ receipt?.validate_undeliver_reason }}
                  </div>

                  <div v-if="receipt.items" class="header-tag">
                    {{
                      $t("TransactionDetailsPageReceiptItemsLengthLabel", [
                        receipt.items.length,
                      ])
                    }}
                  </div>

                  <div
                    v-if="receipt.receiptDistributer.minOrder"
                    class="header-tag"
                  >
                    {{
                      $t("TransactionDetailsPageReceiptHeaderTagMinOrderLabel")
                    }}
                    <span class="u-font--bold u-font--medium">{{
                      receipt.receiptDistributer.minOrder.toLocaleString()
                    }}</span>
                    EGP
                  </div>
                  <div
                    v-if="receipt?.cashbackRedemptionAmount"
                    class="header-tag"
                  >
                    Cashback:
                    <span class="u-font--bold u-font--medium">{{
                      receipt?.cashbackRedemptionAmount
                    }}</span>
                    EGP
                  </div>
                  <div
                    v-if="
                      receipt.receiptStatus &&
                      receipt.receiptStatus != 'pending'
                    "
                    class="header-tag"
                  >
                    {{
                      $t(
                        "TransactionDetailsPageReceiptHeaderTagActualValueLabel",
                        [receipt.actualAmount ? receipt.actualAmount : "null"]
                      )
                    }}
                  </div>
                  <div v-if="receipt.receiptStatus" class="header-tag">
                    {{
                      $t("TransactionDetailsPageReceiptHeaderTagUpdatedAtLabel")
                    }}
                    {{ receipt.updatedAt | moment("DD MMM YYYY  h:mm a") }}
                  </div>
                  <div
                    v-if="
                      receipt.receiptTotalAmount !==
                      receipt.receiptTotalOriginalAmount
                    "
                    class="header-tag"
                  >
                    {{
                      $t(
                        "TransactionDetailsPageReceiptHeaderTagPriceDiferenceLabel"
                      )
                    }}
                    <span class="u-font--bold u-font--medium">{{
                      (
                        receipt.receiptTotalAmount -
                        receipt.receiptTotalOriginalAmount
                      ).toLocaleString()
                    }}</span>
                    EGP
                    <span class="u-font--bold u-font--medium"
                      >({{
                        (((receipt.receiptTotalAmount -
                          receipt.receiptTotalOriginalAmount) /
                          receipt.receiptTotalOriginalAmount) *
                          100)
                          | formatFloatValues
                      }}%)</span
                    >
                  </div>
                  <div
                    v-if="
                      receipt.provider &&
                      receipt.provider.id !== -1 &&
                      showDeliveryDateTag(receipt)
                    "
                    class="header-tag"
                  >
                    {{
                      $t(
                        "TransactionDetailsPageReceiptHeaderTagExpectedDeliveryDateLabel",
                        [
                          receipt.expectedDeliveryDate
                            ? receipt.expectedDeliveryDate
                            : "no value",
                        ]
                      )
                    }}
                  </div>
                  <template v-if="receipt.promocode">
                    <div class="header-tag--green">
                      Promo:
                      {{ receipt.promocode.code }}
                    </div>
                    <div class="header-tag--green">
                      {{ receipt.promocode.discount_amount }} EGP
                    </div>

                    <div class="header-tag--red">
                      اجمالي الفاتورة قبل الخصم:
                      {{
                        receiptMonetaryValue(
                          receipt.receiptTotalOriginalAmount,
                          receipt.receiptTotalAmountVisual
                        )
                      }}
                    </div>

                    <div class="header-tag--green">
                      إجمالي الفاتورة بعد الخصم :
                      {{
                        receiptMonetaryValue(
                          receipt.receiptTotalOriginalAmount,
                          receipt.receiptTotalAmountVisual
                        ) - receipt.promocode.discount_amount
                      }}
                    </div>
                  </template>
                  <template
                    v-if="receipt.target && receipt.receiptDistributer.id != -1"
                  >
                    <div class="header-tag">
                      Target Type: {{ receipt.target.type }}
                    </div>
                    <div class="header-tag">
                      Target Value: {{ receipt.target.value }}
                    </div>
                    <div class="header-tag">
                      Target Progress: {{ receipt.target.progress }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="u-display-flex right-container">
                <div
                  v-if="
                    receipt.receiptTotalAmount ||
                    receipt.receiptTotalOriginalAmount
                  "
                  :class="{
                    'header-tag--unvalid':
                      receipt.receiptDistributer.minOrder >
                        receipt.receiptTotalAmount ||
                      receipt.receiptDistributer.id == -1,
                    'header-tag--valid':
                      receipt.receiptDistributer.minOrder <=
                        receipt.receiptTotalAmount &&
                      receipt.receiptDistributer.id !== -1,
                  }"
                >
                  <span class="u-font--bold u-font--medium">{{
                    (
                      receipt.receiptTotalAmountVisual ||
                      receipt.receiptTotalOriginalAmount
                    ).toLocaleString()
                  }}</span>
                  EGP
                </div>
              </div>
            </div>
          </template>
          <div class="card-body">
            <div class="c-fatura-card__header-buttons">
              <el-button
                v-if="receipt.receiptId"
                @click.stop="changeValidateUndeliveredReasonClicked(receipt)"
                class="u-right-margin--1x"
              >
                Validate undelivered reason
              </el-button>
              <el-button
                v-if="receipt.receiptId"
                @click.stop="changeStatusClicked(receipt)"
                class="u-right-margin--1x"
              >
                Change Status
              </el-button>
              <el-button
                v-if="
                  receipt.target &&
                  receipt.receiptDistributer &&
                  receipt.receiptDistributer.id &&
                  receipt.receiptDistributer.id != -1
                "
                @click.stop="showTargetDetails(receipt)"
                class="u-right-margin--1x"
              >
                {{ `Target Details` }}
              </el-button>
              <el-button
                v-if="
                  receipt.receiptDistributer &&
                  receipt.receiptDistributer.id &&
                  receipt.receiptDistributer.id != -1
                "
                @click.stop="showWholesalerInfoClicked(receipt)"
                class="u-right-margin--1x"
                >{{ $t("TransactionDetailsWholesalerInfoBtnLabel") }}</el-button
              >
              <el-date-picker
                v-if="receipt.provider && receipt.provider.id != 1"
                v-model="receipt.requiredDeliveryDate"
                type="date"
                :placeholder="
                  $t('TransactionDetailsPageRetailerDateFieldPlaceholder')
                "
                class="u-right-margin--1x"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                clearable
                @change="updateReceiptDeliveryDate($event, receipt)"
                :disabled="disableActualDeliveryDateField(receipt)"
                :picker-options="datePickerOptions"
              >
              </el-date-picker>
              <el-tooltip
                :content="$t('TransactionDetailsPrintToolTipMessage')"
                :disabled="canPrint"
                placement="top"
                effect="dark"
              >
                <div>
                  <el-button
                    :disabled="isPrintLoading || !canPrint"
                    @click.stop="onPrintReceipt(receipt)"
                    class="u-right-margin--1x"
                    >{{
                      $t("TransactionDetailsPageReceiptPrintBtn")
                    }}</el-button
                  >
                </div>
              </el-tooltip>

              <!-- <el-button
                v-if="receipt.receiptStatus == 'inprogress'"
                @click.stop="changeActualValue(receipt)"
                class="u-right-margin--1x"
                >{{
                  $t(
                    "TransactionDetailsPageReceiptSetActualValueAndCompleteBtn"
                  )
                }}
              </el-button> -->

              <el-button
                v-if="receipt.receiptStatus == 'pending'"
                @click.stop="cancelReceipt(receipt)"
                class="u-right-margin--1x"
                >{{ $t("TransactionDetailsPageReceiptCancelBtn") }}
              </el-button>
              <!-- <el-dropdown
                class="u-right-margin--1x steal-btn"
                @command="stealAnItem"
                trigger="click"
              >
                <el-button
                  plain
                  :disabled="!receipt.stealItems"
                  @click.stop="onOpenStealButton"
                >
                  <div class="u-display-flex u-justify-content--between">
                    <div class="">
                      {{
                        $t("TransactionDetailsPageReceiptStealItemsDropdown")
                      }}
                    </div>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </div>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <div
                    v-for="(distributer, key) in receipt.stealItems"
                    :key="key"
                  >
                    <el-dropdown-item disabled class="u-right">{{
                      distributer[0].distributer.name
                    }}</el-dropdown-item>
                    <el-dropdown-item
                      v-for="(item, key) in distributer"
                      :key="key"
                      :command="{ stolenItem: item, receipt }"
                    >
                      <div
                        class="u-display-flex u-direction--ltr u-flex--row-reverse"
                      >
                        <div>{{ item.product.product.name }}</div>
                        <div class="u-color--red-light">
                          ({{ item.priceDifference }})
                        </div>
                      </div>
                    </el-dropdown-item>
                  </div>
                </el-dropdown-menu>
              </el-dropdown> -->
            </div>
            <div>
              {{ $t("TransactionDetailsPageReceiptReceiptScoresLabel") }}
              <el-rate
                disabled
                v-model="receipt.review_score"
                :allow-half="false"
              ></el-rate>
            </div>
            <div>
              <h1>
                {{ $t("TransactionDetailsPageReceiptReviewNotesLabel") }}
              </h1>
              <el-input
                class="u-bottom-margin--2x"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                :placeholder="
                  $t('TransactionDetailsPageReceiptInputReviewNotesPlaceholder')
                "
                v-model="receipt.review_notes"
                disabled
              ></el-input>
            </div>

            <div>
              <h1>
                {{ $t("TransactionDetailsPageReceiptWholesalerNotesLabel") }}
              </h1>
              <el-input
                class="u-bottom-margin--2x"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                :placeholder="
                  $t(
                    'TransactionDetailsPageReceiptInputWholeSalerNotesPlaceholder'
                  )
                "
                v-model="receipt.notes"
                @change="saveCurrentReceipts"
                :disabled="disableWholesalerNotes(receipt)"
              ></el-input>
            </div>

            <div
              class="u-display-flex u-justify-content--end u-bottom-margin--1x"
              v-if="!disableWholesalerNotes(receipt)"
            >
              <el-button type="primary" @click="addNote(receipt)">
                Add Note
              </el-button>
            </div>
            <div
              class="u-display-flex u-justify-content--end u-bottom-margin--1x"
              v-if="showEditReceiptButton(transactionDetails, receipt)"
            >
              <el-button
                type="info"
                @click="changeEditReceiptFlag(receipt, true)"
              >
                {{ $t("GlobalsEditBtn") }}
              </el-button>
              <el-button
                type="primary"
                v-if="receipt.provider && receipt.provider.id != -1"
                @click="addNewItemsClicked(receipt)"
              >
                {{ $t("TransactionDetailsPageBtnAddNewItemsLabel") }}
              </el-button>
            </div>

            <el-table
              :data="receipt.items"
              style="width: 100%"
              row-class-name="no-hover"
              cell-class-name="t-coulumn"
              header-cell-class-name="u-text--center"
              :default-sort="{
                prop: 'priceDifference',
                order: 'descending',
              }"
            >
              <el-table-column
                prop="product.name"
                :label="$t('TransactionDetailsPageReceiptDetailsColumnTitle')"
                width="300"
              >
                <template slot-scope="scope">
                  <div class="u-display-flex">
                    <div class="u-right-margin--2x">
                      <!-- <img
                        width="50"
                        height="50"
                        style="object-fit: scale-down"
                        :src="scope.row.product.image"
                      /> -->
                      <div
                        :style="{
                          'background-image':
                            'url(' + scope.row.product.image + ')',
                        }"
                        class="product-image"
                      ></div>
                      <div class="old-ribbon" v-if="scope.row.offer">
                        {{
                          scope.row.offer.type == "hot_offer"
                            ? "Hot offer"
                            : "Flash offer"
                        }}
                      </div>
                    </div>
                    <div class="u-direction--rtl">
                      <div>
                        {{ scope.row.product.name }}
                      </div>
                      <div class="u-display-flex u-direction--ltr">
                        <div class="u-right-padding--half-x">
                          {{ scope.row.unit.name }}
                        </div>
                        <div class="u-direction--rtl">
                          <span
                            v-if="
                              scope.row.productUnit &&
                              scope.row.productUnit.itemsPerUnit
                            "
                            >{{ scope.row.productUnit.itemsPerUnit }}</span
                          >
                          <span v-else>{{
                            scope.row.product.itemsPerUnit
                          }}</span>
                        </div>
                      </div>
                      <div class="u-font--xsmall">
                        ID:
                        <strong>{{ scope.row.productId }}</strong>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="originalQuantity"
                :label="$t('TransactionDetailsPageReceiptQuantityColumnTitle')"
                width="80"
              >
                <template slot-scope="scope">
                  {{ scope.row.quantity }}
                  <br />
                  <el-button
                    v-if="receipt.showChangeButtonFlag"
                    type="text"
                    @click="changeItemQuantity(scope.row)"
                    >{{
                      $t("TransactionDetailsPageReceiptQuantityColumnBtn")
                    }}</el-button
                  >
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('TransactionDetailsPageReceiptPriceBigColumnTitle')"
              >
                <el-table-column
                  prop="price"
                  :label="
                    $t('TransactionDetailsPageReceiptProviderPriceColumnTitle')
                  "
                  width="80"
                >
                  <template slot-scope="scope">
                    {{ scope.row.price }}
                    <br />
                    <el-button
                      v-if="receipt.showChangeButtonFlag"
                      type="text"
                      @click="changeItemPrice(scope.row)"
                      >{{
                        $t(
                          "TransactionDetailsPageReceiptProviderPriceColumnBtn"
                        )
                      }}</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="priceDifference"
                  :label="
                    $t('TransactionDetailsPageReceiptPriceDiferenceColumnTitle')
                  "
                  width="80"
                >
                  <template slot-scope="scope">
                    <div
                      v-if="scope.row.priceDifference"
                      class="u-color--red-light"
                    >
                      {{ scope.row.priceDifference }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="cashPrice"
                  v-if="transactionDetails.method == 'credit'"
                  :label="
                    $t('TransactionDetailsPageReceiptCashPriceColumnTitle')
                  "
                  width="80"
                >
                  <template slot-scope="scope">
                    {{ scope.row.originalPrice }}
                    <br />
                    <el-button
                      v-if="receipt.showChangeButtonFlag"
                      type="text"
                      @click="changeItemOriginalPrice(scope.row)"
                      >{{
                        $t("TransactionDetailsPageReceiptCashPriceColumnBtn")
                      }}</el-button
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  prop="CreditPrice"
                  v-if="transactionDetails.method == 'credit'"
                  :label="
                    $t('TransactionDetailsPageReceiptAgelPriceColumnTitle')
                  "
                  width="80"
                >
                  <template slot-scope="scope">
                    {{ bundlePrice(scope.row.originalPrice) }}
                    <br />
                    <el-button
                      v-if="receipt.showChangeButtonFlag"
                      type="text"
                      @click="changeItemOriginalPrice(scope.row)"
                      >{{
                        $t("TransactionDetailsPageReceiptAgelPriceColumnBtn")
                      }}</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="originalPrice"
                  :label="
                    $t('TransactionDetailsPageReceiptFaturaPriceColumnTitle')
                  "
                  width="80"
                >
                  <template slot-scope="scope">
                    <div
                      class="u-display-flex u-align-items--center"
                      v-if="scope.row.offer"
                      style="text-decoration: line-through"
                    >
                      {{
                        transactionIsCredit
                          ? faturaPrice(scope.row.offer.original_price)
                          : scope.row.offer.original_price
                      }}
                    </div>
                    {{ faturaPrice(scope.row.originalPrice) }}
                    <br />
                    <el-button
                      v-if="receipt.showChangeButtonFlag"
                      type="text"
                      @click="changeItemOriginalPrice(scope.row)"
                      >{{
                        $t("TransactionDetailsPageReceiptFaturaPriceColumnBtn")
                      }}</el-button
                    >
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="الاجمالى" width="80">
                <template slot-scope="scope">
                  {{ scope.row.quantity * scope.row.price }}
                </template>
              </el-table-column>
              <el-table-column label>
                <template slot-scope="scope">
                  <div class="u-display-flex u-align-items--center">
                    <el-dropdown
                      v-if="
                        receipt.showChangeButtonFlag &&
                        !scope.row.newlyAddedItem
                      "
                      :class="
                        $store.state.isRTL
                          ? 'u-left-padding--1x'
                          : 'u-right-padding--1x'
                      "
                      @command="changeItemDistributerClicked"
                    >
                      <el-button plain>
                        <i class="el-icon-refresh"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          v-for="(provider, key) in scope.row
                            .possibleDistributers"
                          :key="key"
                          :disabled="
                            provider.id == scope.row.distributerId ||
                            selectedDistributers.indexOf(
                              provider.id.toString()
                            ) < 0
                          "
                          :command="{
                            currentItem: scope.row,
                            selectedDistributer: provider,
                          }"
                          >{{ provider.name }} -
                          {{ provider.price }}</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>

                    <el-button
                      type="danger"
                      plain
                      icon="el-icon-delete"
                      v-if="
                        scope.row.distributerId !== -1 &&
                        receipt.showChangeButtonFlag
                      "
                      @click="
                        changeItemDistributerClicked({
                          currentItem: scope.row,
                          selectedDistributer: {
                            id: -1,
                            price: scope.row.originalPrice,
                            name: 'Canceled Items',
                          },
                          newlyCancelledItem: true,
                        })
                      "
                    >
                    </el-button>

                    <span
                      v-if="
                        scope.row.isFaturaProduct &&
                        scope.row.isFaturaProduct == 1
                      "
                      :class="
                        $store.state.isRTL
                          ? 'u-right-padding--1x'
                          : 'u-left-padding--1x'
                      "
                    >
                      {{
                        $t("TransactionDetailsPageReceiptFaturaProductLabel")
                      }}
                      <emoji
                        :class="
                          $store.state.isRTL
                            ? 'u-right-padding--1x'
                            : 'u-left-padding--1x'
                        "
                        :emoji="{ id: 'star', skin: 1 }"
                        :size="15"
                      />
                    </span>
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <div
              class="u-display-flex u-justify-content--end u-top-margin--1x"
              v-if="receipt.showChangeButtonFlag"
            >
              <el-button type="danger" @click="cancelReceiptsEdit">
                {{ $t("GlobalCancelBtn") }}
              </el-button>
              <el-button type="success" @click="saveOrderReceipts">
                {{ $t("GlobalsSaveBtn") }}
              </el-button>
            </div>
          </div>
          <ReceiptPossibleDistributers
            :receipt="receipt"
            v-if="
              receipt.receiptStatus === 'pending' ||
              receipt.receiptStatus === 'pending_cancellation'
            "
          />
        </el-collapse-item>
      </el-collapse>
    </div>
    <TransactionWholesalerInfoDialog
      v-model="showWholesalerInfoDialog"
      :wholesalerInfoObject="clickedWholesaler"
      :userAreaId="transactionDetails.user.regionId"
    />
    <TransactionAdditionalItemsDialog
      :showDialog="showAddItemsDialog"
      @closDialog="closeAddItemsDialog"
      :receiptDistributer="clickedReceiptDistributer"
      @saveClicked="addNewItems"
    />
    <TransactionChangeReceiptStatusDialog
      v-model="showChangeStatusDialog"
      :receipt="clickedReceipt"
    />
    <TransactionChangeReceiptReasonDialog
      v-model="showChangeValidateUndeliveredReasonDialog"
      :receipt="clickedReceipt"
    />
    <TransactionTargetDetailsDialog v-model="clickedTarget" />
  </div>
</template>

<script>
import { transactions, receipts } from "@/services";
import { Emoji } from "emoji-mart-vue";
import _, { find, isEmpty, includes } from "lodash";
import TransactionWholesalerInfoDialog from "./TransactionWholesalerInfoDialog.vue";
import TransactionAdditionalItemsDialog from "./TransactionAdditionalItemsDialog.vue";
import { utils } from "../../../../utils";
import TransactionChangeReceiptStatusDialog from "./TransactionChangeReceiptStatusDialog.vue";
import TransactionTargetDetailsDialog from "./TransactionTargetDetailsDialog";
import TransactionChangeReceiptReasonDialog from "./TransactionChangeReceiptReasonDialog";
import ReceiptPossibleDistributers from "./ReceiptPossibleDistributers";
import {
  matchTrxItemToReceiptItem,
  impersontationMatchTrxItemToReceiptItem,
  impersontationMatchReceiptItemToFetchedItem,
} from "@/services/trxDetailsHelpers";
export default {
  name: "TransactionReceiptsHolder",
  components: {
    Emoji,
    TransactionWholesalerInfoDialog,
    TransactionAdditionalItemsDialog,
    TransactionChangeReceiptStatusDialog,
    TransactionTargetDetailsDialog,
    TransactionChangeReceiptReasonDialog,
    ReceiptPossibleDistributers,
  },
  filters: {
    formatFloatValues: function (value) {
      if (!value) return "";
      let filteredValue = value.toFixed(1);
      let output = 0;
      if (parseFloat(filteredValue) > 0) {
        output = filteredValue;
      }
      return output.toString();
    },
  },
  computed: {
    receiptsFromStore() {
      return JSON.parse(
        JSON.stringify(this.$store.getters.getTransactionReceipts)
      );
    },
    transactionTotalAmount() {
      return this.$store.getters.getTransactionTotalAmount;
    },
    transactionCreditTotalAmount() {
      return this.$store.getters.getTransactionCreditTotalAmount;
    },
    transactionTotalOriginalAmount() {
      return this.$store.getters.getTransactionTotalOriginalAmount;
    },
    transactionCreditTotalOriginalAmount() {
      return this.$store.getters.getTransactionCreditTotalOriginalAmount;
    },
    transactionDetails() {
      return this.$store.getters.getTransactionDetails;
    },
    canPrint() {
      return this.$store.getters.getCanPrint;
    },
    selectedDistributers() {
      return this.$store.getters.getSelectedDistributers;
    },
    transactionItems() {
      return this.$store.getters.getTransactionItems;
    },
    creditConfig() {
      return this.$store.getters.getCreditConfig;
    },
    orderDistributers() {
      return this.$store.getters.getOrderDistributers;
    },
    transactionIsCredit() {
      return this.transactionDetails.method == "credit";
    },
  },
  watch: {
    receiptsFromStore: {
      handler(val) {
        this.receipts = [...val];
      },
      deep: true,
    },
  },
  data() {
    return {
      isPrintLoading: false,
      receipts: [],
      showWholesalerInfoDialog: false,
      clickedWholesaler: null,
      showAddItemsDialog: false,
      clickedReceiptDistributer: null,
      datePickerOptions: {
        disabledDate: this.dateDisabler,
      },
      showChangeStatusDialog: false,
      showChangeValidateUndeliveredReasonDialog: false,
      clickedReceipt: null,
      clickedTarget: null,
    };
  },
  methods: {
    dateDisabler(date) {
      let trxDate = new Date(this.transactionDetails.createdAt);
      trxDate.setDate(trxDate.getDate() - 1);
      return date < trxDate;
    },
    saveCurrentReceipts() {
      this.$store.commit("setTransactionReceipts", this.receipts);
    },
    onPrintReceipt(receipt) {
      this.isPrintLoading = true;
      if (!receipt.receiptId) {
        this.$notify({
          type: "warning",
          title: this.$t(
            "TransactionDetailsPrintReceiptSaveValidationNotificationTitle"
          ),
          message: this.$t(
            "TransactionDetailsPrintReceiptSaveValidationNotificationMessage"
          ),
        });
        this.isPrintLoading = false;
        return;
      }
      transactions
        .retrivePrintInfo(receipt.receiptId)
        .then(({ receiptPrintDetails }) => {
          if (receiptPrintDetails.status) window.open(receiptPrintDetails.file);
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            title: this.$t("TransactionDetailsPrintErrorTitle"),
            message: error.response.data.error,
          });
        })
        .finally(() => {
          this.isPrintLoading = false;
        });
    },
    stealAnItem({ stolenItem, receipt }) {
      const trxItems = JSON.parse(
        JSON.stringify(this.$store.getters.getTransactionItems)
      );
      let stolenItemObject = find(trxItems, (trxItem) => {
        return matchTrxItemToReceiptItem(trxItem, stolenItem);
      });
      if (!isEmpty(stolenItemObject)) {
        stolenItemObject.price = stolenItem.price;
        stolenItemObject.priceDifference = stolenItem.priceDifference;
        stolenItemObject.distributerId = receipt.receiptDistributer.id;
        stolenItemObject.provider.id = receipt.receiptDistributer.id;
        stolenItemObject.provider.name = receipt.receiptDistributer.name;
        this.$store.commit("setTransactionItems", trxItems);
        this.$store.dispatch("recalculateReceipts");
      }
    },
    bundlePrice(price) {
      let faturaPrice = 0;

      if (!this.transactionDetails.bundle) {
        faturaPrice =
          price *
          (this.transactionDetails.method == "credit"
            ? Number.parseFloat(this.creditConfig.Credit_Premium)
            : 1);
      } else {
        faturaPrice =
          price *
          (this.transactionDetails.method == "credit"
            ? Number.parseFloat(
                this.transactionDetails.bundle.premiumPercentage
              )
            : 1);
      }
      faturaPrice = (Math.round(faturaPrice * 4) / 4).toFixed(2);

      return faturaPrice;
    },
    faturaPrice(price) {
      let faturaPrice = 0;
      //check if it transaction has a bundle or not
      if (!this.transactionDetails.bundle) {
        faturaPrice =
          price *
          (this.transactionDetails.method == "credit"
            ? 1 + Number.parseFloat(this.creditConfig.Credit_Premium)
            : 1);
      } else {
        faturaPrice =
          price *
          (this.transactionDetails.method == "credit"
            ? 1 +
              Number.parseFloat(
                this.transactionDetails.bundle.premiumPercentage
              )
            : 1);
      }
      faturaPrice = _.round(faturaPrice, 2);
      // console.log("🚀 ~ file: TransactionReceiptsHolder.vue:941 ~ faturaPrice ~ faturaPrice:", faturaPrice)
      // faturaPrice = (Math.round(faturaPrice * 4) / 4).toFixed(2);
      return faturaPrice;
    },
    onOpenStealButton(e) {
      e.stopPropagation();
    },
    changeItemPrice(item) {
      this.$prompt(
        this.$t("TransactionDetailsChangeItemPricePromptMessage", [item.price]),
        this.$t("TransactionDetailsChangeItemPricePromptTitle"),
        {
          confirmButtonText: this.$t(
            "TransactionDetailsChangeItemPricePromptConfirmBtn"
          ),
          cancelButtonText: this.$t(
            "TransactionDetailsChangeItemPricePromptCancelBtn"
          ),
          inputType: "number",
          inputValue: item.price,
        }
      ).then(({ value }) => {
        item.price = parseFloat(value);
        item.priceDifference = (item.price - item.originalPrice).toFixed(2);
        if (item.priceDifference < 0) {
          item.originalPrice = item.price;
          item.priceDifference = (item.price - item.originalPrice).toFixed(2);
        }
        this.$store.commit(
          "setTransactionReceipts",
          JSON.parse(JSON.stringify(this.receipts))
        );

        const currentTrxItems = JSON.parse(
          JSON.stringify(this.transactionItems)
        );
        const correspondingTrxItem = find(currentTrxItems, (trxItem) => {
          return matchTrxItemToReceiptItem(trxItem, item);
        });
        correspondingTrxItem.price = parseFloat(value);
        correspondingTrxItem.priceDifference = (
          correspondingTrxItem.price - correspondingTrxItem.originalPrice
        ).toFixed(2);
        if (correspondingTrxItem.priceDifference < 0) {
          correspondingTrxItem.originalPrice = correspondingTrxItem.price;
          correspondingTrxItem.priceDifference = (
            correspondingTrxItem.price - correspondingTrxItem.originalPrice
          ).toFixed(2);
        }
        this.$store.commit("setTransactionItems", currentTrxItems);
        this.$store.dispatch("recalculateReceipts");
      });
    },
    changeItemOriginalPrice(item) {
      this.$prompt(
        this.$t("TransactionDetailsChangeItemFaturaPricePromptMessage", [
          item.price,
        ]),
        this.$t("TransactionDetailsChangeItemFaturaPricePromptTitle"),
        {
          confirmButtonText: this.$t(
            "TransactionDetailsChangeItemPricePromptConfirmBtn"
          ),
          cancelButtonText: this.$t(
            "TransactionDetailsChangeItemPricePromptCancelBtn"
          ),
          inputType: "number",
          inputValue: item.originalPrice,
        }
      ).then(({ value }) => {
        item.originalPrice = parseFloat(value);
        item.priceDifference = (item.price - item.originalPrice).toFixed(2);
        if (item.priceDifference < 0) {
          this.$message.error(
            this.$t("TransactionDetailsChangeItemFaturaPriceErrorMessage")
          );
          item.originalPrice = item.price;
          item.priceDifference = (item.price - item.originalPrice).toFixed(2);
        }
        this.$store.commit(
          "setTransactionReceipts",
          JSON.parse(JSON.stringify(this.receipts))
        );
        const currentTrxItems = JSON.parse(
          JSON.stringify(this.transactionItems)
        );
        const correspondingTrxItem = find(currentTrxItems, (trxItem) => {
          return matchTrxItemToReceiptItem(trxItem, item);
        });
        correspondingTrxItem.originalPrice = item.originalPrice;
        correspondingTrxItem.priceDifference = item.priceDifference;
        this.$store.commit("setTransactionItems", currentTrxItems);
        this.$store.dispatch("recalculateReceipts");
      });
    },
    changeItemQuantity(item) {
      this.$prompt(
        this.$t("TransactionDetailsChangeItemQuantityPromptMessage", [
          item.quantity,
        ]),
        this.$t("TransactionDetailsChangeItemQuantityPromptTitle"),
        {
          confirmButtonText: this.$t(
            "TransactionDetailsChangeItemQuantityPromptConfirmBtn"
          ),
          cancelButtonText: this.$t(
            "TransactionDetailsChangeItemQuantityPromptCancelBtn"
          ),
          inputType: "number",
          inputValue: item.quantity,
        }
      ).then(({ value }) => {
        item.quantity = parseInt(value);
        this.$store.commit(
          "setTransactionReceipts",
          JSON.parse(JSON.stringify(this.receipts))
        );
        const currentTrxItems = JSON.parse(
          JSON.stringify(this.transactionItems)
        );
        const correspondingTrxItem = find(currentTrxItems, (trxItem) => {
          return matchTrxItemToReceiptItem(trxItem, item);
        });
        correspondingTrxItem.quantity = parseInt(value);
        this.$store.commit("setTransactionItems", currentTrxItems);
        this.$store.dispatch("recalculateReceipts");
      });
    },
    changeActualValue(recipt) {
      this.$prompt(
        this.$t("TransactionDetailsChangeActualValuePromptMessage"),
        this.$t("TransactionDetailsChangeActualValuePromptTitle"),
        {
          confirmButtonText: this.$t(
            "TransactionDetailsChangeActualValuePromptConfirmBtn"
          ),
          cancelButtonText: this.$t(
            "TransactionDetailsChangeActualValuePromptCancelBtn"
          ),
          inputType: "number",
          inputErrorMessage: "Invalid input",
        }
      )
        .then(({ value }) => {
          let actualVal = {
            status: "complete",
            actualAmount: Number.parseInt(value),
          };
          transactions
            .updateReceiptActualAmount(recipt.receiptId, actualVal)
            .then((res) => {
              if (res) {
                const currentReceipts = JSON.parse(
                  JSON.stringify(this.receipts)
                );
                const index = currentReceipts.findIndex(
                  (reciptItem) => reciptItem.receiptId == recipt.receiptId
                );
                currentReceipts[index].actualAmount =
                  res.data.receipt.actualAmount;
                currentReceipts[index].receiptStatus = res.data.receipt.status;
                this.$store.commit("setTransactionReceipts", currentReceipts);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("TransactionDetailsChangeActualValueErrorMessage"),
          });
        });
    },
    cancelReceipt(receipt) {
      this.$confirm(
        this.$t("TransactionDetailsCancelReceiptMessageBoxMessage"),
        this.$t("TransactionDetailsCancelReceiptMessageBoxTitle"),
        {
          confirmButtonText: this.$t(
            "TransactionDetailsCancelReceiptMessageBoxOkBtn"
          ),
          cancelButtonText: this.$t(
            "TransactionDetailsCancelReceiptMessageBoxCancelBtn"
          ),
        }
      )
        .then(async () => {
          try {
            const response = await this.$store.dispatch(
              "cancelReceiptClicked",
              receipt
            );
            if (response && response.status == 200) {
              await this.$store.dispatch(
                "fetchTrxDetailsData",
                this.$store.getters.getTransactionId
              );
            }
          } catch (error) {
            console.log("cancelReceipt error :>> ", error);
            this.$message({
              message: this.$t("GlobalErrorMessage"),
              type: "error",
              duration: 2000,
            });
          }
        })

        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("TransactionDetailsErrorMessage"),
          });
        });
    },
    changeItemDistributerClicked(options) {
      this.$store.dispatch("tableItemSteal", options);
    },
    async updateReceiptDeliveryDate(inputValue, receipt) {
      const updateBody = {
        requiredDeliveryDate: inputValue,
      };
      try {
        const res = await receipts.updateReceiptData(
          receipt.receiptId,
          updateBody
        );
        if (res && res.status == 200) {
          this.$globalFunctions.popupMessageWrapper(
            this.$t("TransactionDetailsPageActualDateUpdateSuccessMessage"),
            "success",
            2000
          );
        }
      } catch (error) {
        this.$globalFunctions.popupMessageWrapper(
          this.$t("GlobalErrorMessage"),
          "error",
          2000
        );
      }
      setTimeout(() => {
        this.$store.dispatch("getLatestData");
      }, 1500);
    },
    async addNote(receipt) {
      const updateBody = {
        notes: receipt.notes,
      };
      console.log(updateBody, "here we go");
      try {
        const res = await receipts.addNoteToReceipt(
          receipt.receiptId,
          updateBody
        );
        if (res && res.status == 200) {
          this.$globalFunctions.popupMessageWrapper(
            "Wholesaler Note Updated Successfully ",
            "success",
            2000
          );
        }
      } catch (error) {
        this.$globalFunctions.popupMessageWrapper(
          this.$t("GlobalErrorMessage"),
          "error",
          2000
        );
      }
      setTimeout(() => {
        this.$store.dispatch("getLatestData");
      }, 1500);
    },
    showWholesalerInfoClicked(receiptOject) {
      const currentOrderDistributers = JSON.parse(
        JSON.stringify(this.orderDistributers)
      );
      const clickedWholesalerId = receiptOject.receiptDistributer.id;
      if (currentOrderDistributers.hasOwnProperty(clickedWholesalerId)) {
        this.clickedWholesaler = currentOrderDistributers[clickedWholesalerId];
        this.showWholesalerInfoDialog = true;
      } else {
        this.$globalFunctions.popupMessageWrapper(
          "Wholesaler info not fetched",
          "error",
          1500
        );
      }
    },
    showEditReceiptButton(trxDetails, receiptObject) {
      let flag = false;
      const receiptStatusToEdit = ["accepted", "pending"];
      if (
        trxDetails &&
        trxDetails.state &&
        (trxDetails.state == "in progress" || trxDetails.state == "pending")
      ) {
        flag = true;
        if (
          receiptObject &&
          receiptObject.receiptStatus &&
          !receiptStatusToEdit.includes(receiptObject.receiptStatus)
        ) {
          flag = false;
        }
      }

      return flag;
    },
    editReceiptItemsClicked(receiptObject) {
      receiptObject.showChangeButtonFlag = true;
      this.saveCurrentReceipts();
    },
    changeEditReceiptFlag(receiptObject, flagValue) {
      receiptObject.showChangeButtonFlag = flagValue;
      this.saveCurrentReceipts();
    },
    addNewItemsClicked(receiptObject) {
      this.showAddItemsDialog = true;
      this.clickedReceiptDistributer = JSON.parse(
        JSON.stringify(receiptObject.receiptDistributer)
      );
    },
    closeAddItemsDialog() {
      this.showAddItemsDialog = false;
      this.clickedReceiptDistributer = null;
    },
    addNewItems(productsList) {
      const currentTransactionItems = JSON.parse(
        JSON.stringify(this.transactionItems)
      );
      for (const item of productsList) {
        const itemAlreadyInReceipt = find(
          currentTransactionItems,
          (trxItem) => {
            return impersontationMatchTrxItemToReceiptItem(trxItem, item);
          }
        );

        if (itemAlreadyInReceipt) {
          itemAlreadyInReceipt.quantity += parseInt(item.quantity);
          if (itemAlreadyInReceipt.distributerId == -1) {
            const canceledReceipt = this.receipts.filter((item) => {
              return item.provider?.id == -1;
            });
            let matchedReceiptItem = find(
              canceledReceipt[0].items,
              (receiptItem) => {
                return impersontationMatchReceiptItemToFetchedItem(
                  receiptItem,
                  item
                );
              }
            );
            const selectedDistributer = find(
              matchedReceiptItem?.possibleDistributers,
              (i) => {
                return item?.entityId == i.id;
              }
            );
            const changeDistributerOptions = {
              currentItem: matchedReceiptItem,
              selectedDistributer: selectedDistributer,
            };
            this.changeItemDistributerClicked(changeDistributerOptions);
            this.showAddItemsDialog = false;
            return;
          }
        } else {
          let newTrxItem = {};
          newTrxItem.price = parseFloat(item.price);
          newTrxItem.quantity = parseInt(item.quantity);
          newTrxItem.originalPrice = parseFloat(item.price);
          newTrxItem.originalQuantity = parseInt(item.quantity);
          newTrxItem.priceDifference = "0.00";
          newTrxItem.distributerId = item.entityId;
          newTrxItem.provider = {
            id: this.clickedReceiptDistributer.name,
            name: this.clickedReceiptDistributer.name,
          };
          newTrxItem.possibleDistributers = [
            {
              id: this.clickedReceiptDistributer.name,
              name: this.clickedReceiptDistributer.name,
              price: item.price,
            },
          ];
          if (item.unit && item.unitId) {
            newTrxItem.unit = {
              name: item.unit,
              id: item.unitId,
            };
          }
          newTrxItem.newlyAddedItem = true;
          newTrxItem.product = { ...item, productId: item.id };
          newTrxItem.productId = item.id;
          newTrxItem.isFaturaProduct = item.isFaturaProduct;
          newTrxItem = JSON.parse(JSON.stringify(newTrxItem));
          newTrxItem.offer = item.offer || null;
          currentTransactionItems.push(newTrxItem);
        }
      }
      this.$store.commit("setTransactionItems", currentTransactionItems);
      this.$store.dispatch("recalculateReceipts");
      this.showAddItemsDialog = false;
      this.saveOrderReceipts();
    },
    async saveOrderReceipts() {
      try {
        const response = await this.$store.dispatch("saveReceipts");
        if (response && response.status == 200) {
          await this.$store.dispatch("getLatestData");
        }
      } catch (error) {
        const errorMessage = utils.errorMessageExtractor(error);

        await this.$store.dispatch(
          "fetchTrxDetailsData",
          this.$store.getters.getTransactionId
        );
        this.$message({
          message: errorMessage,
          type: "error",
          duration: 2000,
        });
        this.$store.dispatch("getLatestData");
        this.updateReceiptsErrorHandler(error);
      }
    },
    async cancelReceiptsEdit() {
      await this.$store.dispatch("getLatestData");
    },
    updateReceiptsErrorHandler(errorObject) {
      const responseObject = errorObject.response;
      let htmlStringOutput = `<div><ul>`;
      if (responseObject.data && responseObject.data.data) {
        const errorsData = responseObject.data.data;
        for (let key of Object.keys(errorsData)) {
          errorsData[key].forEach((item) => {
            htmlStringOutput += `<li>
            <span>${item}</span>
          </li>`;
          });
        }
      }
      htmlStringOutput += ` </ul></div> `;
      this.$alert(htmlStringOutput, "Update Receipt Error", {
        dangerouslyUseHTMLString: true,
        lockScroll: false,
      });
    },
    disableActualDeliveryDateField(receiptObject) {
      let statusValue = receiptObject.receiptStatus;
      if (statusValue && includes(["pending", "inprogress"], statusValue)) {
        return false;
      }
      return true;
    },
    showDeliveryDateTag(receiptObject) {
      let statusValue = receiptObject.receiptStatus;
      if (statusValue && !includes(["pending"], statusValue)) {
        return true;
      }
      return false;
    },
    disableWholesalerNotes(receiptObject) {
      if (receiptObject && receiptObject.receiptId) {
        return false;
      }
      return true;
      // let flag = false;

      // if (
      //   receiptObject &&
      //   receiptObject.receiptStatus &&
      //   (receiptObject.receiptStatus == "completed" ||
      //     receiptObject.receiptStatus == "cancelled" ||
      //     receiptObject.receiptStatus == "rejected")
      // ) {
      //   flag = true;
      // }

      // return flag;
    },
    receiptMonetaryValue(originalAmount, visualAmount) {
      if (originalAmount > visualAmount) {
        return originalAmount;
      }
      return visualAmount;
    },
    changeStatusClicked(receiptObject) {
      this.showChangeStatusDialog = true;
      this.clickedReceipt = JSON.parse(JSON.stringify(receiptObject));
    },
    changeValidateUndeliveredReasonClicked(receiptObject) {
      this.showChangeValidateUndeliveredReasonDialog = true;
      this.clickedReceipt = JSON.parse(JSON.stringify(receiptObject));
    },
    async showTargetDetails(receiptOject) {
      this.clickedTarget = JSON.parse(JSON.stringify(receiptOject.target));
    },
  },
  created() {
    this.$store.commit("clearTrxData");

    this.receipts = JSON.parse(JSON.stringify(this.receiptsFromStore));
  },
};
</script>

<style lang="scss" scoped>
.offer-flag {
  width: 60px;
}
.image-holder {
  height: 80px;
  width: 80px;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.old-ribbon {
  position: absolute;
  top: 5px;
  width: 50px;
  height: 10px;
  padding: 2px;
  // margin-left: 5px;
  border-radius: 2px 0px 0px 2px;
  background-color: #ea4335;
  color: white;
  // display: inline-block;
  font-size: 8px;
  // margin-top: 10px;
  line-height: 5px;
}

.old-ribbon::before {
  content: "";
  display: block;
  position: absolute;
  right: -5px;
  top: 0;
  border: solid 5px #ea4335;
  border-left-width: 0;
  border-right-color: transparent;
}

.product-image {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  position: relative;
}
</style>
