<template>
  <el-dialog
    :title="$t('TransactionDetailsPageAddItemsDialogLabel')"
    :visible.sync="showDialogInternal"
    @close="closeDialogAction"
    width="50%"
  >
    <div>
      <div class="u-bottom-margin--2x">
        <el-select
          v-model="searchText"
          filterable
          remote
          clearable
          reserve-keyword
          :placeholder="
            $t('TransactionDetailsPageAddItemsDialogSearchPlaceholder')
          "
          :remote-method="remoteMethod"
          :loading="loading"
          @change="itemClickedInDropdown"
          style="width: 100%"
        >
          <el-option
            v-for="item in fetchedProducts"
            :key="item.id"
            :value="item"
          >
            <div class="u-display-flex u-direction--ltr">
              <span>
                {{ ` ${item.name}` }}
              </span>
            </div>
          </el-option>
        </el-select>
      </div>
    </div>
    <div>
      <div class="u-bottom-margin--2x">
        {{ $t("TransactionDetailsPageAddItemsDialogAddedItemsLabel") }}
      </div>

      <el-table :data="chosenItems" border stripe>
        <el-table-column
          prop="name"
          :label="
            $t('TransactionDetailsPageAddItemsDialogTableColumnProductName')
          "
        />
        <el-table-column
          prop="storeName"
          :label="$t('TransactionDetailsPageAddItemsDialogTableColumnUnit')"
        >
          <template slot-scope="scope">
            <span>
              <span class="u-direction--rtl">
                <el-select
                  v-model="scope.row.unitId"
                  @change="unitChanged(scope.row)"
                >
                  <el-option
                    v-for="item in scope.row.productUnits"
                    :key="item.unitId"
                    :label="item.unit"
                    :value="item.unitId"
                  >
                  </el-option>
                </el-select>
              </span>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="cashPrice"
          :label="$t('TransactionDetailsPageAddItemsDialogTableColumnPrice')"
        />
        <el-table-column prop="price" :label="`Offer Price`">
          <template slot-scope="scope">
            <div>
              {{ scope.row.offer?.price }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="Quantity"
          :label="$t('TransactionDetailsPageAddItemsDialogTableColumnQuantity')"
        >
          <template slot-scope="scope">
            <div>
              <el-input v-model="scope.row.quantity" type="number"></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100px" :label="``">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-close"
              circle
              style="color: red"
              @click="removeRow(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelClicked">{{ $t("GlobalsCancelBtn") }}</el-button>
      <el-button type="success" @click="saveClicked">{{
        $t("GlobalsSaveBtn")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { providersService } from "@/services/providers.service.js";
export default {
  name: "TransactionAdditionalItemsDialog",
  props: {
    showDialog: {
      type: Boolean,
      required: false,
    },
    receiptDistributer: { required: false },
  },
  data() {
    return {
      searchText: "",
      fetchedProducts: [],
      loading: false,
      chosenItems: [],
      showDialogInternal: false,
    };
  },
  methods: {
    cancelClicked() {
      this.closeDialogAction();
    },
    closeDialogAction() {
      this.chosenItems = [];
      this.$emit("closDialog", false);
    },
    async saveClicked() {
      const itemsArray = JSON.parse(JSON.stringify(this.chosenItems));
      this.$emit("saveClicked", itemsArray);
    },
    popupMessageWrapper(message = "", type = "success", duration = 1000) {
      this.$message({ message, type, duration });
    },
    async remoteMethod(query) {
      if (query !== "") {
        const filtersObject = {
          name: query.trim(),
          isInStock: 1,
        };
        const providerId = this.receiptDistributer.id;
        try {
          this.loading = true;
          const res = await providersService.getProviderProducts(
            providerId,
            filtersObject
          );
          if (res) {
            this.fetchedProducts = res.data.data;
          }
        } catch (error) {
          console.log("error :>> ", error);
        }
        this.loading = false;
      }
    },
    removeRow(clickedRow) {
      const newItemsArray = this.chosenItems.filter(
        (user) => user.identifier != clickedRow.identifier
      );
      this.chosenItems = newItemsArray;
    },
    unitChanged(row) {
      const newUnit = row.productUnits.find((pu) => pu.unitId === row.unitId);
      row.cashPrice = newUnit.cashPrice;
      row.price = newUnit.offer ? newUnit.offer.price : newUnit.cashPrice;
      row.unit = newUnit.unit;
      row.offer = newUnit.offer;
    },
    itemClickedInDropdown(data) {
      try {
        const itemAlreadyInserted = this.chosenItems.filter((item) => {
          return item.id === data.id;
        });
        let finalProductsArray = [];

        if (!(itemAlreadyInserted && itemAlreadyInserted.length > 0)) {
          let checkHasOfferFlag = false;
          data.productUnits.forEach((item) => {
            if (item.offer) checkHasOfferFlag = true;
          });
          if (checkHasOfferFlag) {
            let offerProduct = {
              ...data,
              productId: data.id,
              productUnits: data.productUnits.filter((i) => i.offer),
              identifier: data.id + "" + finalProductsArray.length,
            };
            offerProduct = JSON.parse(JSON.stringify(offerProduct));

            offerProduct = {
              ...offerProduct,
              entityId: offerProduct.productUnits[0].entityId,
              unitId: offerProduct.productUnits[0].unitId,
              unit: offerProduct.productUnits[0].unit,
              quantity: 1,
              cashPrice: offerProduct.productUnits[0].cashPrice,
              price: offerProduct.productUnits[0].offer.price,
              offer: offerProduct.productUnits[0].offer,
            };
            finalProductsArray.push(offerProduct);
            let productObject = {
              ...data,
              productId: data.id,
              identifier: data.id + "" + finalProductsArray.length,
              entityId: data.productUnits[0].entityId,
              unitId: data.productUnits[0].unitId,
              unit: data.productUnits[0].unit,
              cashPrice: data.productUnits[0].cashPrice,
              price: offerProduct.productUnits[0].cashPrice,
              quantity: 1,
              offer: null,
            };
            productObject = JSON.parse(JSON.stringify(productObject));
            finalProductsArray.push(productObject);
          } else {
            let productObject = {
              ...data,
              productId: data.id,
              entityId: data.productUnits[0].entityId,
              unitId: data.productUnits[0].unitId,
              unit: data.productUnits[0].unit,
              price: data.productUnits[0].cashPrice,
              cashPrice: data.productUnits[0].cashPrice,
              quantity: 1,
              offer: null,
              identifier: data.id + "" + finalProductsArray.length,
            };
            productObject = JSON.parse(JSON.stringify(productObject));
            finalProductsArray.push(productObject);
          }
        }
        this.chosenItems = [...this.chosenItems, ...finalProductsArray];
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    errorMessageExtractor(errorObject) {
      const errorMessageExistsError =
        errorObject.response &&
        errorObject.response.data &&
        errorObject.response.data.error;
      const errorMessageExistsMessage =
        errorObject.response &&
        errorObject.response.data &&
        errorObject.response.data.message;
      let finalErrorMessage = this.$t(
        "RetailerWalletsPageWalletDefaultErrorMessage"
      );
      if (errorMessageExistsError) {
        finalErrorMessage = errorObject.response.data.error;
      }
      if (errorMessageExistsMessage) {
        finalErrorMessage = errorObject.response.data.message;
      }
      return finalErrorMessage;
    },
  },
  watch: {
    showDialog(newValue) {
      if (newValue === false) {
        this.chosenItems = [];
      }
      this.showDialogInternal = newValue;
    },
  },
};
</script>

<style></style>
