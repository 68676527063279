<template>
  <div>
    <el-dialog :title="`Change Status`" :visible.sync="localVisible">
      <div>
        <el-select
          :placeholder="`Select status`"
          clearable
          v-model="chosenStatus"
          filterable
          style="width: 100%"
          :disabled="possibleStatusesArray.length == 0"
          @change="statusSelected"
          class="u-bottom-margin--2x"
        >
          <el-option
            v-for="(item, key) in possibleStatusesArray"
            :key="item.next_status + key"
            :label="item.next_status"
            :value="item.next_status"
          >
          </el-option>
        </el-select>
        <el-radio-group v-model="chosenReason">
          <template>
            <el-radio
              v-for="item in possibleReasons"
              :key="item"
              class="u-bottom-margin--2x"
              style="display: block"
              :label="item"
              >{{ item }}
            </el-radio>
          </template>
        </el-radio-group>
        <el-input
          v-if="possibleReasons.length > 0"
          v-model="textComment"
          autocomplete="off"
          :placeholder="`Comment`"
        ></el-input>
      </div>
      <span slot="footer">
        <el-button @click="cancelClicked">{{
          $t("GlobalsCancelBtn")
        }}</el-button>
        <el-button type="success" @click="saveClicked">{{
          $t("GlobalsSaveBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-constant-condition */

import { transactions } from "@/services/transactions.service.js";
export default {
  name: "TransactionChangeStatusDialog",
  props: {
    value: { type: Boolean, default: false },
  },
  data() {
    return {
      localVisible: null,
      possibleStatusesArray: [],
      chosenStatus: null,
      possibleReasons: [],
      chosenReason: null,
      textComment: null,
    };
  },
  computed: {
    transactionDetails() {
      return this.$store.getters.getTransactionDetails;
    },
  },
  watch: {
    localVisible(value, oldValue) {
      if (value !== oldValue) {
        this.$emit("input", value);
      }
      if (value === true) {
        this.fetchNextStatuses();
      } else {
        this.chosenStatus = null;
        this.possibleStatusesArray = [];
        this.clearValues();
      }
    },
    value(newVal) {
      this.localVisible = newVal;
    },
  },
  created() {
    this.localVisible = this.value;
  },
  methods: {
    cancelClicked() {
      this.localVisible = false;
    },
    async fetchNextStatuses() {
      try {
        const currentStatus = this.transactionDetails.state;
        const response = await transactions.getTransactionNextStatus({
          currentStatus,
          status_type: "transaction",
        });
        if (response && response.status == 200) {
          this.possibleStatusesArray = response.data.data;
        }
      } catch (error) {
        console.log("fetchNextStatusesError", error);
      }
    },
    statusSelected(clickedStatus) {
      this.clearValues();
      const chosenItem = this.possibleStatusesArray.filter(
        (item) => item.next_status == clickedStatus
      );
      if (chosenItem.length && chosenItem[0].nextStatusReasons) {
        this.possibleReasons = chosenItem[0].nextStatusReasons;
      }
    },
    clearValues() {
      this.possibleReasons = [];
      this.chosenReason = null;
      this.textComment = null;
    },
    async saveClicked() {
      try {
        const invalidData = this.validateData();
        if (invalidData) return;

        let apiObject = { state: this.chosenStatus };
        if (this.chosenReason) apiObject.reason = this.chosenReason;
        if (this.textComment) apiObject.comment = this.textComment;

        const transactionId = this.$store.getters.getTransactionId;
        const response = await transactions.updatedChangeTransactionStatus(
          transactionId,
          apiObject
        );
        if (true || response & (response.status == 200)) {
          this.displayMessage("Status updated", "success");
          setTimeout(() => {
            this.$store.dispatch("getLatestData");
            this.localVisible = false;
          }, 1500);
        }
      } catch (errorObject) {
        console.log("changeStatusError", errorObject);
        if (true) {
          this.displayMessage("Status updated", "success");
          setTimeout(() => {
            this.$store.dispatch("getLatestData");
            this.localVisible = false;
          }, 1500);
        }
        // const errorMessageExists =
        //   errorObject.response &&
        //   errorObject.response.data &&
        //   errorObject.response.data.message;
        // let finalErrorMessage = this.$t(
        //   "RetailerWalletsPageWalletDefaultErrorMessage"
        // );
        // if (errorMessageExists) {
        //   finalErrorMessage = errorObject.response.data.message;
        // }
        // this.displayMessage(finalErrorMessage);
      }
    },
    validateData() {
      let finalFlag = false;
      if (this.chosenStatus) {
        if (this.possibleReasons.length > 0) {
          if (!this.chosenReason) {
            this.displayMessage("No reason chosen");
            return true;
          }
          if (
            this.chosenReason &&
            (!this.textComment ||
              (this.textComment && this.textComment.length == 0))
          ) {
            this.displayMessage("No comment added");
            return true;
          }
        }
      }
      return finalFlag;
    },
    displayMessage(currentMessage, type = "error") {
      this.$message({ message: currentMessage, type, duration: 1500 });
    },
  },
};
</script>

<style>
/* .el-dialog {
  z-index: 1999 !important;
} */
</style>
