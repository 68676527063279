<template>
  <el-card class="box-card u-bottom-margin--2x u-centered">
    <h2>Most Suited Providers</h2>
    <div class="u-display-flex u-justify-content--end u-bottom-margin--2x">
      <el-button type="primary" size="default" :disabled="!selectedDistributers.length"
        @click="handleRerouteDistributer">
        Reroute
      </el-button>
    </div>

    <template>
      <el-table :data="getBestDistributer" border stripe :row-key="(row) => row.providerData.entityId"
        @select="setSelectedDistributers" @select-all="setSelectedDistributers">
        <el-table-column type="selection" width="50" reserve-selection :selectable="canSelectRow"></el-table-column>
        <el-table-column prop="providerData" label="Provider Name">
          <template slot-scope="props">
            <div>{{ props.row.providerData.entityName ?? "unknown" }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="providerData" label="Min Order">
          <template slot-scope="props">
            <div>{{ props.row.providerData.minOrderAmount ?? 0 }} EGP</div>
          </template>
        </el-table-column>
        <el-table-column prop="total" label="Total">
          <template slot-scope="props">
            <div>
              {{ props.row.total ? props.row.total : 0 }}
              EGP
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="priceDifference" label="Price Difference ">
          <template slot-scope="props">
            <div class="u-color--red-light">
              {{ props.row.priceDifference ?? 0 }}
              EGP
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="numberOfItems" label="Items"> </el-table-column>
        <el-table-column prop="providerData" label="Delivery Time">
          <template slot-scope="props">
            <div>
              {{ props.row.providerData.deliveryTime ?? "-" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column type="expand" width="40">
          <template slot-scope="props">
            <h4>Receipt Details</h4>
            <el-table :data="props.row.productsData" border>
              <el-table-column prop="productsData" :label="$t('TransactionDetailsPageReceiptDetailsColumnTitle')"
                width="300">
                <template slot-scope="scope">
                  <div class="u-display-flex">
                    <div class="u-right-margin--2x">
                      <div :style="{
        'background-image':
          'url(' + scope.row.productImage + ')',
      }" class="product-image"></div>
                      <div class="old-ribbon" v-if="scope.row.isOffer">
                        {{ "Hot offer" }}
                      </div>
                    </div>
                    <div class="u-direction--rtl">
                      <div>
                        {{ scope.row.productName }}
                      </div>
                      <div class="u-display-flex u-direction--ltr">
                        <div class="u-right-padding--half-x">
                          {{ scope.row.unitName }}
                        </div>
                        <div class="u-direction--rtl">
                          <span v-if="scope.row.itemsPerUnit">{{
        scope.row.itemsPerUnit ?? 0
      }}</span>
                        </div>
                      </div>
                      <div class="u-font--xsmall">
                        ID:
                        <strong>{{ scope.row.productId }}</strong>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="orderableQuantity" label="Quantity">
                <template slot-scope="scope">
                  {{ scope.row.orderableQuantity }}
                </template>
              </el-table-column>
              <el-table-column prop="cashPrice" label="whsPrice" width="100">
                <template slot-scope="scope">
                  <div v-if="scope.row.isOffer" style="text-decoration: line-through"
                    class="u-display-flex u-align-items--center">
                    {{ scope.row.cashPrice }}

                    EGP
                  </div>
                  {{ scope.row.offerPrice || scope.row.cashPrice }}
                </template></el-table-column>
              <el-table-column prop="differenceInPricePerItem" label="Price Difference">
                <template slot-scope="scope">
                  <div class="u-color--red-light">
                    {{ scope.row.differenceInPricePerItem }}
                    EGP
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="originalOrderPrice" label="Original Price">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.originalOrderPrice }}
                    EGP
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="itemNewTotalPrice" label="Total">
                <template slot-scope="scope">
                  {{ scope.row.itemNewTotalPrice }} EGP
                </template>
              </el-table-column>
            </el-table>
            <h4 class="u-color--red-light">Unavailable Products</h4>
            <el-table :data="props.row.cancelProducts">
              <el-table-column prop="product.name" :label="$t('TransactionDetailsPageReceiptDetailsColumnTitle')">
              </el-table-column>
              <el-table-column prop="originalQuantity" label="Quantity">
                <template slot-scope="scope">
                  <div class="u-color--red-light">
                    {{ scope.row.quantity }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </el-card>
</template>

<script>
import { utils } from "@/utils";
import { transactions } from "@/services";

export default {
  name: "ReceiptPossibleDistributers",
  props: {
    receipt: { type: Object, default: () => { } },
  },
  data() {
    return {
      selectedDistributers: [],
      getBestDistributer: [],
    };
  },
  async mounted() {
    this.getReceiptsRerouteDistributersList(this.receipt.receiptId);
  },

  computed: {
    transactionDetails() {
      return this.$store.getters.getTransactionDetails;
    },
    selectedDistributersFromStore() {
      return this.$store.getters.getSelectedDistributers;
    },
  },
  watch: {
    selectedDistributers(items) {
      if (!items.length) {
        this.getBestDistributer = this.getBestDistributer.map((item) => {
          return {
            ...item,
            disabled: true,
          };
        });

        return;
      }
      this.getBestDistributer = this.getBestDistributer.map((item) => {
        const isDisabled = !!items.find((selectedItem) => {
          return (
            selectedItem.providerData.entityId === item.providerData.entityId
          );
        });

        return {
          ...item,
          disabled: isDisabled,
        };
      });
    },
  },
  created() { },
  methods: {
    setSelectedDistributers(items) {
      if (items.length > 1) {
        utils.popupMessageWrapper(
          "Please select only one provider",
          "error",
          1500
        );
        return;
      }
      this.selectedDistributers = items;
      if (items.length === 1) {
        this.getReceiptsRerouteDistributersList(this.receipt.receiptId);
      }
    },

    async getReceiptsRerouteDistributersList(receiptId) {
      try {
        const response = await transactions.getReceiptsRerouteOptions(
          receiptId
        );

        let responseData = response.data?.data;
        const receiptItems = [...this.receipt.items].reduce((acc, curr) => {
          const findSameProduct = acc.find(
            (item) => item.productId === curr.productId
          );
          if (findSameProduct) {
            findSameProduct.quantity = findSameProduct.quantity + curr.quantity;
            return acc;
          }
          return [...acc, { ...curr }];
        }, []);

        this.getBestDistributer = responseData.map((item) => {

          let productsData = item.productsData.reduce((acc, curr) => {
            const findSameProduct = acc.find(
              (item) => item.productId === curr.productId
            );
            if (findSameProduct) {
              findSameProduct.orderableQuantity =
                findSameProduct.orderableQuantity + curr.orderableQuantity;

              if (
                findSameProduct.orderableQuantity >
                findSameProduct.offerMaxPerOrder
              ) {
                curr.orderableQuantity = Math.abs(
                  findSameProduct.orderableQuantity -
                  findSameProduct.offerMaxPerOrder
                );

                if (curr.orderableQuantity !== 0) {
                  findSameProduct.orderableQuantity = Math.abs(
                    findSameProduct.orderableQuantity - curr.orderableQuantity
                  );
                  findSameProduct.itemNewTotalPrice =
                    findSameProduct.orderableQuantity *
                    findSameProduct.offerPrice;
                  findSameProduct.differenceInPricePerItem = Math.abs(
                    findSameProduct.originalOrderPrice -
                    (findSameProduct.offerPrice || findSameProduct.cashPrice)
                  );
                  curr.isOffer = 0;
                  curr.is_original_price_with_offer = true;
                  return [...acc, { ...curr }];
                } else {
                  findSameProduct.itemNewTotalPrice =
                    findSameProduct.orderableQuantity *
                    findSameProduct.offerPrice;
                  findSameProduct.differenceInPricePerItem = Math.abs(
                    findSameProduct.originalOrderPrice -
                    (findSameProduct.offerPrice || findSameProduct.cashPrice)
                  );
                  return acc;
                }
              } else {
                return acc;
              }
            }
            return [...acc, { ...curr }];
          }, []);

          const cancelProducts = receiptItems.filter((receipt) => {
            const checkHasProduct = !item.productsData.some(
              (product) => product.productId === receipt.productId
            );
            return checkHasProduct;
          });

          const total = productsData.reduce((acc, curr) => {
            return acc + curr.itemNewTotalPrice;
          }, 0);

          const priceDifference = Math.abs(
            this.receipt.receiptTotalAmount - total
          );

          return {
            ...item,
            cancelProducts,
            numberOfItems: productsData.length,
            productsData,
            total,
            priceDifference,
            disabled: true,

          };
        });

        return response;
      } catch (error) {
        const errorMessage = utils.errorMessageExtractor(error);
        utils.popupMessageWrapper(errorMessage, "error", 3500);
      }
    },

    canSelectRow(row) {
      return row.disabled;
    },
    async handleRerouteDistributer() {
      await this.$confirm(
        `Are you sure to reroute to this Distributor ${this.selectedDistributers[0].providerData.entityName}?`,
        "Warning.",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.submitReroute();
      });
    },
    async submitReroute() {
      let selectedDistributers = { ...this.selectedDistributers[0] };
      selectedDistributers = {
        items: selectedDistributers.productsData.map((item) => {
          let retrieveItem = {
            productId: item.productId,
            price: item.cashPrice,
            quantity: item.orderableQuantity,
            unitId: item.unitId,
          };
          if (item.offerId) {
            retrieveItem = {
              ...retrieveItem,
              offer_id: item?.offerId ?? null,
            };
            return retrieveItem;
          }
          if (item.is_original_price_with_offer) {
            retrieveItem = {
              ...retrieveItem,
              is_original_price_with_offer: item.is_original_price_with_offer,
            };
            return retrieveItem;
          }
          return retrieveItem;
        }),
        providerId: selectedDistributers.providerData.entityId,
      };
      const receiptId = this.receipt.receiptId;
      try {
        const response = await transactions.submitRerouteRequest(
          receiptId,
          selectedDistributers
        );
        if ((response && response.status === 200) || response.status === 204) {
          await this.$store.dispatch("getLatestData");
        }
      } catch (error) {
        const errorMessage = utils.errorMessageExtractor(error);

        await this.$store.dispatch(
          "fetchTrxDetailsData",
          this.$store.getters.getTransactionId
        );
        this.$message({
          message: errorMessage,
          type: "error",
          duration: 2000,
        });
        this.$store.dispatch("getLatestData");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.small-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.blacklist {
  background: rgb(84, 92, 100);
}

.original {
  background: #85ce61;
}

.receipt {
  background: #cb0300;
}

.old-ribbon {
  position: absolute;
  top: 5px;
  width: 50px;
  height: 10px;
  padding: 2px;
  // margin-left: 5px;
  border-radius: 2px 0px 0px 2px;
  background-color: #ea4335;
  color: white;
  // display: inline-block;
  font-size: 8px;
  // margin-top: 10px;
  line-height: 5px;
}

.old-ribbon::before {
  content: "";
  display: block;
  position: absolute;
  right: -5px;
  top: 0;
  border: solid 5px #ea4335;
  border-left-width: 0;
  border-right-color: transparent;
}

.product-image {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  position: relative;
}

.offer-flag {
  width: 60px;
}
</style>
