<template>
  <div>
    <el-dialog :title="`Change Status`" :visible.sync="localVisible">
      <div>
        <el-select
          :placeholder="`Select status`"
          clearable
          v-model="chosenStatus"
          filterable
          style="width: 100%"
          :disabled="possibleStatusesArray.length == 0"
          @change="statusSelected"
          class="u-bottom-margin--2x"
        >
          <el-option
            v-for="(item, key) in possibleStatusesArray"
            :key="item.next_status + key"
            :label="item.next_status"
            :value="item.next_status"
          >
          </el-option>
        </el-select>
        <el-radio-group v-model="chosenReason">
          <template>
            <el-radio
              v-for="item in possibleReasons"
              :key="item"
              class="u-bottom-margin--2x"
              style="display: block"
              :label="item"
              >{{ item }}
            </el-radio>
          </template>
        </el-radio-group>
        <el-input
          v-if="possibleReasons.length > 0"
          v-model="textComment"
          autocomplete="off"
          :placeholder="`Comment`"
        ></el-input>
        <el-input
          v-if="needActualAmountFlag"
          placeholder="Input Actual Amount"
          type="number"
          v-model="actualValueField"
        />
      </div>
      <span slot="footer">
        <el-button @click="cancelClicked">{{
          $t("GlobalsCancelBtn")
        }}</el-button>
        <el-button type="success" @click="saveClicked">{{
          $t("GlobalsSaveBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-constant-condition */

import { transactions } from "@/services/transactions.service.js";
import { receipts } from "@/services";

export default {
  name: "TransactionChangeReceiptStatusDialog",
  props: {
    value: { type: Boolean, default: false },
    receipt: { type: Object, default: () => {} },
  },
  data() {
    return {
      localVisible: null,
      possibleStatusesArray: [],
      chosenStatus: null,
      possibleReasons: [],
      chosenReason: null,
      textComment: null,
      actualValueField: null,
      needActualAmountFlag: false,
    };
  },
  computed: {
    transactionDetails() {
      return this.$store.getters.getTransactionDetails;
    },
  },
  watch: {
    localVisible(value, oldValue) {
      if (value !== oldValue) {
        this.$emit("input", value);
      }
      if (value === true) {
        this.fetchNextStatuses();
      } else {
        this.chosenStatus = null;
        this.possibleStatusesArray = [];
        this.clearValues();
      }
    },
    value(newVal) {
      this.localVisible = newVal;
    },
  },
  created() {
    this.localVisible = this.value;
  },
  methods: {
    cancelClicked() {
      this.localVisible = false;
    },
    async fetchNextStatuses() {
      try {
        const currentStatus = this.receipt.receiptStatus;
        const response = await transactions.getTransactionNextStatus({
          currentStatus,
          status_type: "receipt",
        });
        if (response && response.status == 200) {
          this.possibleStatusesArray = response.data.data;
        }
      } catch (error) {
        console.log("fetchNextStatusesError", error);
      }
    },
    statusSelected(clickedStatus) {
      this.clearValues();
      const chosenItem = this.possibleStatusesArray.filter(
        (item) => item.next_status == clickedStatus
      );
      if (chosenItem.length) {
        let statusObject = chosenItem[0];
        if (statusObject.nextStatusReasons) {
          this.possibleReasons = statusObject.nextStatusReasons;
        }
        if (statusObject.nextStatusActions?.actions?.needActualAmount) {
          this.needActualAmountFlag = true;
        }
      }
    },
    clearValues() {
      this.possibleReasons = [];
      this.chosenReason = null;
      this.textComment = null;
      this.actualValueField = null;
      this.needActualAmountFlag = false;
    },
    async saveClicked() {
      try {
        let response;
        if (this.needActualAmountFlag) {
          if (!this.actualValueField) {
            this.$globalFunctions.popupMessageWrapper(
              "Please provide actual amount",
              "error",
              2000
            );
            return;
          }
          let apiObject = {
            status: "complete",
            actualAmount: Number.parseInt(this.actualValueField),
          };
          response = await transactions.updateReceiptActualAmount(
            this.receipt.receiptId,
            apiObject
          );
        } else {
          const invalidData = this.validateData();
          if (invalidData) return;

          let apiObject = { status: this.chosenStatus };
          if (this.chosenReason) apiObject.reason = this.chosenReason;
          if (this.textComment) apiObject.comment = this.textComment;

          const receiptId = this.receipt.receiptId;
          response = await receipts.updateReceiptData(receiptId, apiObject);
        }
        if (response && response.status == 200) {
          this.$globalFunctions.popupMessageWrapper(
            "Status updated",
            "success",
            2000
          );
          setTimeout(() => {
            this.$store.dispatch("getLatestData");
            this.localVisible = false;
          }, 1500);
        }
      } catch (errorObject) {
        console.log("changeStatusError", errorObject);
        const errorMessage =
          this.$globalFunctions.errorMessageExtractor(errorObject);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 2000);
      }
    },
    validateData() {
      let finalFlag = false;
      if (this.chosenStatus) {
        if (this.possibleReasons.length > 0) {
          if (!this.chosenReason) {
            this.$globalFunctions.popupMessageWrapper(
              "No reason chosen",
              "error",
              1500
            );
            return true;
          }
          if (
            this.chosenReason &&
            (!this.textComment ||
              (this.textComment && this.textComment.length == 0))
          ) {
            this.$globalFunctions.popupMessageWrapper(
              "No comment added",
              "error",
              1500
            );
            return true;
          }
        }
      }
      return finalFlag;
    },
  },
};
</script>

<style>
/* .el-dialog {
  z-index: 1999 !important;
} */
</style>
