<template>
  <div>
    <el-dialog
      :title="`Validate Undelivered Reason`"
      :visible.sync="localVisible"
    >
      <div>
        <el-select
          :placeholder="`Select Validate Undelivered Reason`"
          clearable
          v-model="chosenStatus"
          filterable
          style="width: 100%"
          :disabled="validateUnbelieverReasons.length === 0"
          @change="statusSelected"
          class="u-bottom-margin--2x"
        >
          <el-option
            v-for="item in validateUnbelieverReasons"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer">
        <el-button @click="cancelClicked">{{
          $t("GlobalsCancelBtn")
        }}</el-button>
        <el-button type="success" @click="saveClicked">{{
          $t("GlobalsSaveBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-constant-condition */

import { transactions } from "@/services/transactions.service.js";

export default {
  name: "TransactionChangeReasonDialog",
  props: {
    value: { type: Boolean, default: false },
    receipt: { type: Object, default: () => {} },
  },
  data() {
    return {
      localVisible: null,
      validateUnbelieverReasons: [],
      chosenStatus: null,
    };
  },

  watch: {
    localVisible(value, oldValue) {
      if (value !== oldValue) {
        this.$emit("input", value);
      }
      if (value === true) {
        this.fetchValidateUnbelieverReason();
      } else {
        this.chosenStatus = null;
        this.validateUnbelieverReasons = [];
        this.clearValues();
      }
    },
    value(newVal) {
      this.localVisible = newVal;
    },
  },
  created() {
    this.localVisible = this.value;
  },
  methods: {
    cancelClicked() {
      this.localVisible = false;
    },
    async fetchValidateUnbelieverReason() {
      try {
        const response =
          await transactions.getTransactionValidateUnbelieverReasons();

        if (response && response.status == 200) {
          this.validateUnbelieverReasons = response.data.data;
        }
      } catch (error) {
        console.log("fetchNextStatusesError", error);
      }
    },
    statusSelected(clickedStatus) {
      this.chosenStatus = clickedStatus;
    },

    async saveClicked() {
      try {
        const requestBody = {
          validate_undeliver_reason: this.chosenStatus,
        };
        const response =
          await transactions.updatedTransactionValidateUnbelieverReasons(
            this.receipt.receiptId,
            requestBody
          );

        if (response && response.status == 200) {
          this.$globalFunctions.popupMessageWrapper(
            "Status updated",
            "success",
            2000
          );
          setTimeout(() => {
            this.$store.dispatch("getLatestData");
            this.localVisible = false;
          }, 1500);
        }
      } catch (errorObject) {
        const errorMessage =
          this.$globalFunctions.errorMessageExtractor(errorObject);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 2000);
      }
    },
  },
};
</script>

<style>
/* .el-dialog {
    z-index: 1999 !important;
  } */
</style>
